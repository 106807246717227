import React from 'react';

import { BrowserRouter } from '@interstellar/react-app-routing';
import { createRoot } from 'react-dom/client';

import App from './components/app/App';
import AppConfigContext from './components/appConfig/AppConfigContext';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import { handleEnvVariables } from './utils/handleEnvVariables';

handleEnvVariables();

const jQueryScript = document.getElementById('jQuery');
const script = document.createElement('script');
script.type = 'text/javascript';
script.src = `https://tags.tiqcdn.com/utag/lloyds/blackhorse/${window.appConfig.REACT_APP_TAG_ENV}/utag.js`;
jQueryScript?.after(script);
const dataLayerScript = document.createElement('script');
dataLayerScript.type = 'text/javascript';
dataLayerScript.id = 'utagData';
jQueryScript?.after(dataLayerScript);

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <AppConfigContext.Provider value={(window as any).appConfig}>
    <BrowserRouter basename={process.env.BASE_PATH}>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </AppConfigContext.Provider>,
);
