import React, { ReactElement } from 'react';

import { Heading, Spinner } from '@constellation/core';
import { ContentProvider, useContent } from '@interstellar/react-app-content';

import { SpinnerProps } from './Spinner.config';
import { StyledGrid, StyledPage } from './Spinner.styled';
import content from '../../content';
import { CommonContent } from '../../content/CommonContent';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';

function SpinnerContainerInner(): ReactElement {
  const isDeviceFound = useDeviceResizing();
  const { spinnerLoadingContent } = useContent<CommonContent>();

  return (
    <StyledPage>
      <StyledGrid>
        <Spinner />
        <Heading
          size={isDeviceFound.mobile ? 's4' : 's5'}
          as={isDeviceFound.mobile ? 'h4' : 'h3'}
          data-testid="spinner"
          align="center"
        >
          {spinnerLoadingContent}
        </Heading>
      </StyledGrid>
    </StyledPage>
  );
}

export default function SpinnerContainer({ brand }: SpinnerProps) {
  return (
    <ContentProvider value={content({ brand }).common}>
      <SpinnerContainerInner />
    </ContentProvider>
  );
}
