import React, { ReactElement } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import { Provider } from 'react-redux';

import content from '../../content';
import Routes from '../../routes';
import store from '../../store';
import { getThemeFromUrl } from '../../utils/getThemeFromUrl';
import useAppConfig from '../appConfig/useAppConfig';
import AppLayout from '../appLayout/Applayout';
import BrandProvider, { Brand } from '../brandProvider';

function App(): ReactElement {
  const configBrand = useAppConfig().BRAND_NAME;
  const brand: Brand = getThemeFromUrl() || configBrand;

  return (
    <Provider store={store}>
      <BrandProvider brand={brand}>
        <ContentProvider
          value={{
            ...content({ brand }).welcome,
            ...content({ brand }).layoutContent,
            ...content({ brand }).common,
          }}
        >
          <AppLayout>
            <Routes brand={brand} />
          </AppLayout>
        </ContentProvider>
      </BrandProvider>
    </Provider>
  );
}

export default App;
