import React, { ReactElement, useEffect, useRef, useState } from 'react';

import {
  BackgroundProvider,
  Button,
  Container,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  pxToRem,
  useWindowSize,
} from '@constellation/core';
import ButtonProps from '@constellation/core/dist/types/components/Button/button.types';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import LoginCredentials from './loginCredentials';
import {
  StyledBackgroundImage,
  StyledBoxContainer,
  StyledContentBox,
  StyledLoginBtnGridItem,
  StyledWelcomeHeader,
} from './Welcome.styled';
import { WelcomeContent } from './WelcomePage.config';
import { authLoginApi } from '../../apis/authLoginApi';
import { MarginNumbers } from '../../components/appConfig/AppConfig';
import LinkButton from '../../components/linkButton/LinkButton';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { DataModelAction } from '../../store/action/dataModal.action';
import { EditEmailAction } from '../../store/action/editEmail.action';
import {
  DOBPostcodeContractsDataAction,
  UpdateDOBPostcodeAction,
} from '../../store/action/registration.action';
import { UpdateEmailAPIAction } from '../../store/action/updateEmail.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dataLayer } from '../../utils/dataLayer';
import * as routes from '../manifest';

export default function WelcomePage(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();
  const isNarrow = pxToRem(windowWidth) < parseFloat(theme.breakpoint_lg);
  const isWide = !isNarrow;
  const Logo = isWide ? theme.assets.logo.wide : theme.assets.logo.base;
  const isLockout = useRef(true);
  const isRegistered = useRef(true);
  const isValidAccount = useRef(true);
  const userName = useRef('');
  const password = useRef('');
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [isLoginCredentialError, setIsLoginCredentialError] = useState(false);
  const {
    welcomeMessage,
    registrationLabel,
    createAccountLabel,
    needHelpText,
    goBackLabel,
  } = useContent<WelcomeContent>();
  const navigate = useNavigate();
  const isDeviceFound = useDeviceResizing();

  useEffect(() => {
    window.appConfig.JOURNEY_NAME = 'Login';
    window.appConfig.JOURNEY_STEP_NAME = 'Welcome page';
    window.appConfig.PAGE_ROLE = 'Landing Page';
    dataLayer();
  }, []);

  useEffect(() => {
    dispatch(
      UpdateDOBPostcodeAction('', { day: '', month: '', year: '' }, true),
    );
    dispatch(
      DOBPostcodeContractsDataAction([], {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: false,
      }),
    );
    dispatch(DataModelAction(false));
    dispatch(UpdateEmailAPIAction(false));
  }, [dispatch]);
  const handleMarginBottom = (): MarginNumbers => {
    if (isDeviceFound.tablet) return '07';
    if (isDeviceFound.mobile) return '04';
    return 'none';
  };

  const handleUserName = (email: string): void => {
    userName.current = email;
  };
  const handlePwd = (pwd: string): void => {
    password.current = pwd;
  };
  const handleNavigateHomepage = (): void => {
    navigate(routes.OtherApp);
  };
  const handleLoginCredentialError = (value: boolean): void => {
    setIsLoginCredentialError(value);
  };
  const handleOnSuccessfulLogin = (): void => {
    if (!isLoginCredentialError) {
      dispatch(ShowHideSpinnerAction(true));
      dispatch(EditEmailAction(userName.current));
      handleNavigateHomepage();
    }
  };
  const handleOnLoginBtn = (): void => {
    setIsLoginClicked(true);
    navigate(routes.LoginWss);
  };
  const handleOnLoginCredentials = (): void => {
    dispatch(ShowHideSpinnerAction(true));
    authLoginApi(userName.current, password.current)
      .then((res) => {
        dispatch(ShowHideSpinnerAction(false));
        if (res) {
          setIsLoginCredentialError(false);
        } else {
          setIsLoginCredentialError(true);
        }
        handleOnSuccessfulLogin();
      })
      .catch(() => {
        dispatch(ShowHideSpinnerAction(false));
        setIsLoginCredentialError(true);
      });
  };
  const handleOnNavigateRegistration = (): void => {
    navigate(routes.RegistrationWss);
  };
  const handleNavigate = (): void => {
    navigate(routes.HelpCentre);
  };
  const handleOnGoBackBtn = (): void => {
    setIsLoginClicked(false);
  };
  const renderButton = (
    btnTxt: string,
    btnVariation?: ButtonProps['variation'],
    dataQaId?: string,
    handleButtonClick?: React.MouseEventHandler<HTMLElement>,
    testId?: string,
  ): ReactElement => (
    <Button
      width="fluid"
      variation={btnVariation}
      onClick={handleButtonClick}
      data-qa-id={dataQaId}
      data-testid={testId}
    >
      {btnTxt}
    </Button>
  );
  const renderHeader = (): ReactElement => (
    <StyledWelcomeHeader>
      <BackgroundProvider value={{ cssValue: theme.config_header_on_dark }}>
        <Container>
          <Grid alignY="center">
            <GridItem xs={6} data-testid="Test-Heading">
              <Logo />
            </GridItem>
          </Grid>
        </Container>
      </BackgroundProvider>
    </StyledWelcomeHeader>
  );
  return (
    <StyledBackgroundImage>
      {renderHeader()}
      <StyledBoxContainer data-qa-id={dataQaIds.loginAndRegistration.container}>
        <StyledContentBox marginBottom={handleMarginBottom()}>
          {isLoginClicked ? (
            <LoginCredentials
              handleOnLoginCredentialError={handleLoginCredentialError}
              handlePwd={handlePwd}
              handleUserName={handleUserName}
              isLockout={isLockout.current}
              isRegistered={isRegistered.current}
              isValidAccount={isValidAccount.current}
              isLoginCredentialError={isLoginCredentialError}
            />
          ) : (
            <Heading size="s5" align="center" aria-level={4}>
              {welcomeMessage}
            </Heading>
          )}
          <Grid alignX={isDeviceFound.mobile ? 'center' : 'justify'}>
            <StyledLoginBtnGridItem xs={isDeviceFound.mobile ? 12 : 6}>
              {renderButton(
                registrationLabel,
                'primary',
                dataQaIds.loginAndRegistration.loginBtn,
                isLoginClicked ? handleOnLoginCredentials : handleOnLoginBtn,
                'loginBtn',
              )}
            </StyledLoginBtnGridItem>
            <GridItem xs={isDeviceFound.mobile ? 12 : 6}>
              {renderButton(
                isLoginClicked ? goBackLabel : createAccountLabel,
                'secondary',
                dataQaIds.loginAndRegistration.createOnlineAccBtn,
                isLoginClicked
                  ? handleOnGoBackBtn
                  : handleOnNavigateRegistration,
                'createOnlineAccBtn',
              )}
            </GridItem>
          </Grid>
          <Grid alignX="center">
            <ContentGroup marginBottom="none" marginTop="05">
              <LinkButton testId="needHelp" handleButtonClick={handleNavigate}>
                {needHelpText}
              </LinkButton>
            </ContentGroup>
          </Grid>
        </StyledContentBox>
      </StyledBoxContainer>
    </StyledBackgroundImage>
  );
}
