import { ILoginInfo } from '../action/login.action';

export interface ILoginState {
  emailAddress: string;
}
const initialState = {
  emailAddress: '',
};

export const LoginReducer = (
  state: ILoginState = initialState,
  action: ILoginInfo = {
    type: '',
    ...initialState,
  },
): ILoginState => {
  if (action.type === 'FORGOT_EMAIL') {
    return {
      ...state,
      emailAddress: action.emailAddress || '',
    };
  }
  return state;
};
