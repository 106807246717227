import React, { ReactElement } from 'react';

import { Text, ContentGroup } from '@constellation/core';
import Information from '@constellation/core/icons/Information';
import { useContent } from '@interstellar/react-app-content';

import { EmailFieldProps } from './emailField.config';
import {
  StyledErrorBox,
  StyledErrorText,
  StyledEmailContainer,
  StyledTextField,
  StyledTooltip,
  StyledInfoIcon,
} from './styledEmailFieldComponent';
import dataQaIds from '../../dataModel/dataQaIds';
import { LoginAndRegistrationWssContent } from '../../routes/loginAndRegistrationWss/LoginAndRegistrationWss.config';

export default function EmailField({
  label,
  name,
  error,
  inputWidth,
  fieldMarginBottom,
  fieldMarginLeft,
  fieldMarginRight,
  fieldMarginTop,
  onChange,
  onBlur,
  onKeyDown,
  inputRef,
  testId,
  dataQaId,
  inputValue,
}: EmailFieldProps): ReactElement {
  const { emailAddressLabel, emailTooltipContent } =
    useContent<LoginAndRegistrationWssContent>();
  return (
    <ContentGroup
      marginBottom={fieldMarginBottom}
      marginLeft={fieldMarginLeft}
      marginRight={fieldMarginRight}
      marginTop={fieldMarginTop}
    >
      {label !== '' && (
        <ContentGroup marginBottom="03">
          <Text weight="bold" size="s2">
            {label}
          </Text>
        </ContentGroup>
      )}
      {error && (
        <StyledErrorBox>
          <Information color="critical" iconSize="md" size="s2" />
          <StyledErrorText marginLeft="03" size="s2">
            {error}
          </StyledErrorText>
        </StyledErrorBox>
      )}
      <StyledEmailContainer
        isErrorFound={
          (typeof error === 'boolean' || typeof error === 'string') &&
          error !== ''
        }
        inputWidth={inputWidth}
        data-testid="styledContainer"
      >
        <StyledTextField
          type="text"
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          data-testid={testId}
          ref={inputRef}
          data-qa-id={dataQaId}
          defaultValue={inputValue}
          autoComplete="off"
        />
        <StyledTooltip
          data-qa-id={dataQaIds.loginAndRegistration.emailTooltip}
          target={<StyledInfoIcon iconSize="lg" size="s1" color="inherit" />}
          title={emailAddressLabel}
        >
          <Text size="s2">{emailTooltipContent}</Text>
        </StyledTooltip>
      </StyledEmailContainer>
    </ContentGroup>
  );
}
