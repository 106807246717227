import { Text, Tooltip } from '@constellation/core';
import { Information } from '@constellation/core/icons';
import styled from 'styled-components';

import { device } from '../../utils/breakpoints';
import { FieldProps } from '../appConfig/AppConfig';

export const StyledEmailContainer = styled('div')<FieldProps>`
  border-radius: ${({ theme }) => theme.spacing_02};
  background: ${({ theme }) => theme.color_input_background_default};
  border: 1px solid ${({ theme }) => theme.color_input_border_default};
  box-sizing: border-box;
  &:hover {
    border-color: ${({ theme }) => theme.color_input_border_default};
  }
  ${({ isErrorFound, theme }) =>
    isErrorFound && `border-color: ${theme.color_border_critical};`}
  &:focus-within {
    border: 1px solid
      ${({ isErrorFound, theme }) =>
        isErrorFound ? theme.color_input_border_default : 'transparent'};
    box-shadow:
      0px 0px 0px 2px ${({ theme }) => theme.color_background_neutral_default_1},
      0px 0px 0px 4px ${({ theme }) => theme.color_background_panel_brand_1};

    ${({ isErrorFound, theme }) =>
      isErrorFound && `border-color: ${theme.color_border_critical};`}
    transition-duration: 0.1s;
    transition-property: border, box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
  }
  ${({ inputWidth }) =>
    inputWidth === 'fluid' ? 'width: 100%;' : ' max-width: 21rem;'}
  padding: 11px 15px;
  display: flex;
  align-items: center;
`;

export const StyledTextField = styled.input(
  ({ theme }) => `
  padding: 0;
  border: none;
  border-radius: ${theme.spacing_02};
  background: transparent;
  outline: none;
  color: ${theme.color_text_default_1};
  font-family: ${theme.typography_text_font_family_css};
  font-size: ${theme.typography_text_font_size_s2};
  line-height: ${theme.typography_text_line_height_s2_css};
  width: 100%;
  margin-right: ${theme.spacing_02};
`,
);
export const StyledButtonContainer = styled('div')`
  margin-left: auto;
`;

export const StyledErrorText = styled(Text)`
  color: ${({ theme }) => theme.color_text_critical} !important;
`;

export const StyledInfoIcon = styled(Information)`
  color: #0f7eb1 !important;
`;

export const StyledErrorBox = styled('div')`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing_03};
`;
export const StyledTooltip = styled(Tooltip)`
  width: max-content;
  > span:first-child {
    margin-right: 0.7rem;
    margin-left: 0.5rem;
    @media ${device.md} {
      margin-right: 12.6rem;
    }
    @media ${device.lg} {
      margin-right: 19.77rem !important;
    }
    margin-bottom: 0.4rem;
    box-shadow: 0.5rem 0.5rem 3rem -1.5rem !important;
  }
  > span:last-child {
    position: fixed !important;
    margin-bottom: 0.4rem;
  }
  margin-bottom: 0.3rem !important;
`;
