import React, { ReactElement } from 'react';

import { Grid, Icon, Heading, Container } from '@constellation/core';
import { ContentProvider, useContent } from '@interstellar/react-app-content';

import { NavigatePreviousLinkProps } from './NavigatePrevious.config';
import {
  StyledPrevContentGroup,
  StyledHeader,
  StyledPreviousLink,
} from './styledPreviousLink';
import content from '../../content';
import { CommonContent } from '../../content/CommonContent';
import PreviousLinkStepper from '../previousLinkStepper/previousLinkStepper';

function NavigatePreviousLinkInner({
  navigateRoute,
  headingText,
  dataQaId,
}: NavigatePreviousLinkProps): ReactElement {
  const { previousTitle } = useContent<CommonContent>();
  return (
    <>
      <Grid alignX="left" alignY="center">
        <StyledPrevContentGroup
          marginBottom="none"
          className="navigatePreviousLink"
        >
          <StyledPreviousLink
            className="linkBtn"
            onClick={navigateRoute}
            data-testid="navigatePreviousLink"
            data-qa-id={dataQaId}
          >
            <Icon name="chevron-left" color="brand" />
            {previousTitle}
          </StyledPreviousLink>
          <PreviousLinkStepper />
        </StyledPrevContentGroup>
      </Grid>
      <Container marginBottom="05" padding="none">
        <StyledHeader marginBottom="none">
          <Heading as="h1" size="s4" marginLeft="none" marginBottom="none">
            {headingText}
          </Heading>
        </StyledHeader>
      </Container>
    </>
  );
}

export default function NavigatePreviousLink({
  navigateRoute,
  headingText,
  dataQaId,
  brand,
}: NavigatePreviousLinkProps) {
  return (
    <ContentProvider value={content({ brand }).common}>
      <NavigatePreviousLinkInner
        headingText={headingText}
        navigateRoute={navigateRoute}
        dataQaId={dataQaId}
      />
    </ContentProvider>
  );
}
