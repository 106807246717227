import React, { ReactElement, useState, useEffect } from 'react';

import {
  Button,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useDispatch, useSelector } from 'react-redux';

import CustomerIdentification from './customerIdentification';
import { ForgotEmailContent } from './forgotEmail.config';
import { authAPI } from '../../apis/authApi';
import { authSMSSender } from '../../apis/authSMSSenderApi';
import { DateProps } from '../../components/appConfig/AppConfig';
import { StyledBox } from '../../components/appConfig/common.styled';
import FormStepper from '../../components/formStepper/FormStepper';
import NavigatePreviousLink from '../../components/navigatePreviousLink';
import NeedHelp from '../../components/needHelp/NeedHelp';
import OTPAuthentication from '../../components/otpAuthentication/otpAuthentication';
import RegistrationAuthenticate from '../../components/registrationAuthenticate/registrationAuthenticate';
import RegistrationYourDetails from '../../components/registrationYourDetails/registrationYourDetails';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import {
  changeStepAction,
  changeNumberOfStepsAction,
} from '../../store/action/formStep.action';
import { toggleAction } from '../../store/action/toggler.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dateFormat } from '../../utils/dateFormat';

export default function ForgotEmail({
  phoneNumber,
  brand,
  mtaCustomerId,
  handlePassDetails,
  isContactFound,
  isCustomerIdentificationError,
  numberOfSteps,
  handleCustomerIdentityError,
  isForgotEmailWss,
  handleMultiAgreement,
  isMultiAgreement,
}): ReactElement {
  const [date, setDate] = useState<DateProps>({
    day: undefined,
    month: undefined,
    year: undefined,
  });

  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [isPostCodeError, setIsPostCodeError] = useState(true);
  const [isOTP, setIsOTP] = useState(false);
  const [postCode, setPostCode] = useState('');
  const [isDateError, setDateError] = useState(true);
  const dispatch = useDispatch();
  const isDeviceFound = useDeviceResizing();
  const [journeySessionId, setJourneySessionId] = useState('');
  const [isApiCalled, setIsApiCalled] = useState(false);
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const utilsStore = useSelector((state: RootState) => state.UtilsReducer);
  const {
    yourDetailsHeading,
    authenticateHeading,
    apiErrorMessage,
    continueLabel,
    sendPasscodeLabel,
    forgottenEmailLink,
  } = useContent<ForgotEmailContent>();
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const handleOnDate = (value: any): void => setDate(value);

  const handleOnPostCodeError = (value: boolean): void =>
    setIsPostCodeError(value);
  const handleOnDateError = (value: boolean): void => setDateError(value);
  const handleOnPostCode = (value: string): void => setPostCode(value);
  const handleOnSessionId = (value: string): void => {
    setJourneySessionId(value);
  };
  useEffect(() => {
    dispatch(changeNumberOfStepsAction(2));
  }, [dispatch]);

  const handleOnStep = (): void => {
    dispatch(
      changeStepAction(
        stepNumber < numberOfSteps ? stepNumber + 1 : numberOfSteps,
      ),
    );
  };

  const handleOnPostcodeFormat = (): string =>
    `${postCode.split(' ').join('')}`;
  const renderOTPComponent = (): void => setIsOTP(true);

  const renderStepTitle = (): string => {
    if (stepNumber === 1) {
      return yourDetailsHeading;
    }
    return authenticateHeading;
  };
  const handleOnClickContinue = (): void => {
    setIsContinueClicked(true);
    if (
      !isDateError &&
      !isPostCodeError &&
      stepNumber === 1 &&
      !isCustomerIdentificationError
    ) {
      dispatch(ShowHideSpinnerAction(true));
      setIsApiCalled(true);
      authAPI(`${dateFormat(date, '-')}/${handleOnPostcodeFormat()}`)
        .then((res) => {
          dispatch(ShowHideSpinnerAction(false));
          handlePassDetails(res, { isMultiAgreement: false });
          setIsApiCalled(false);
          handleOnStep();
        })
        .catch((err: any) => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          if (err.message === apiErrorMessage) {
            handleCustomerIdentityError(true);
            setDateError(true);
          }
        });
    } else if (isCustomerIdentificationError) {
      handleCustomerIdentityError(false);
      setIsContinueClicked(false);
    } else if (stepNumber === 2) {
      dispatch(ShowHideSpinnerAction(true));
      setIsApiCalled(true);
      authSMSSender(mtaCustomerId)
        .then((res: any) => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          setJourneySessionId(res.authSessionId);
          renderOTPComponent();
        })
        .catch(() => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
        });
    }
  };
  const renderContinueButtonLabel = (): string => {
    if (stepNumber === 1) {
      return continueLabel;
    }
    return sendPasscodeLabel;
  };

  const handleOnPreviousLink = () => {
    if (stepNumber === 2 && !isOTP && isMultiAgreement) {
      handleMultiAgreement(true);
      dispatch(toggleAction(true));
    } else if (stepNumber === 2 && (isForgotEmailWss || !isWss) && !isOTP) {
      dispatch(toggleAction(true));
    } else if (isOTP) {
      setIsOTP(false);
    } else if (stepNumber === 1 && !isForgotEmailWss) {
      window.history.back();
    } else {
      dispatch(changeStepAction(stepNumber - 1));
    }
  };

  const renderStepContent = (): ReactElement => {
    if (isCustomerIdentificationError) {
      return <CustomerIdentification />;
    }
    if (stepNumber === 2 && !isOTP) {
      return (
        <RegistrationAuthenticate
          phoneNumber={phoneNumber}
          isNumberFound={isContactFound}
        />
      );
    }
    if (stepNumber === 2 && !isOTP) {
      return (
        <RegistrationAuthenticate
          phoneNumber={phoneNumber}
          isNumberFound={isContactFound}
        />
      );
    }
    if (stepNumber === 2 && isOTP) {
      return (
        <OTPAuthentication
          brand={brand}
          isForgotEmail
          isMobileFound={isDeviceFound.mobile}
          sessionId={journeySessionId}
          phoneNumber={phoneNumber}
          handleSendOtp={handleOnClickContinue}
          mtaCustomerId={mtaCustomerId}
          isForgetPassword={false}
          handleSessionId={handleOnSessionId}
        />
      );
    }
    return (
      <RegistrationYourDetails
        isContinueClicked={isContinueClicked}
        handleOnPostCodeError={handleOnPostCodeError}
        handleOnDateError={handleOnDateError}
        handleOnDate={handleOnDate}
        handleOnPostCode={handleOnPostCode}
      />
    );
  };
  const handleOnButtonRenderCondition = (): boolean => {
    if (stepNumber === 1) {
      return true;
    }
    return stepNumber === 2 && isContactFound && !isOTP;
  };
  const renderButtonGroup = (): ReactElement => (
    <ContentGroup marginRight="03" marginLeft="03" marginBottom="none">
      <Grid alignX="right">
        {handleOnButtonRenderCondition() && (
          <Button
            data-qa-id={dataQaIds.loginAndRegistration.confirmAndContinueBtn}
            data-testid="continueBtn"
            onClick={handleOnClickContinue}
          >
            {renderContinueButtonLabel()}
          </Button>
        )}
      </Grid>
    </ContentGroup>
  );
  const handleOnRender = (): ReactElement =>
    isApiCalled && utilsStore.showSpinner ? (
      <SpinnerContainer brand={brand} />
    ) : (
      <Grid>
        <GridItem lg={2} />
        <GridItem lg={8}>
          <NavigatePreviousLink
            headingText=""
            navigateRoute={handleOnPreviousLink}
          />
          <Text size="s2" weight="bold">
            {forgottenEmailLink}
          </Text>
          <Heading size="s7" marginBottom="03" aria-level={2} marginTop="05">
            {renderStepTitle()}
          </Heading>
          <FormStepper activeStep={stepNumber} numberOfSteps={numberOfSteps} />
          <StyledBox padding={stepNumber !== 1}>
            <Grid>
              {renderStepContent()}
              <GridItem>{renderButtonGroup()}</GridItem>
            </Grid>
          </StyledBox>
          <NeedHelp hasHeading dataQaId={dataQaIds.needhelp.regContainer} />
        </GridItem>
        <GridItem lg={2} />
      </Grid>
    );
  return handleOnRender();
}
