import React, { ReactElement, useState } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import { Routes, Route } from '@interstellar/react-app-routing';

import CookiePolicy from './cookiePolicy/cookiePolicy';
import DownPage from './downPage/downPage';
import ForgotEmail from './forgotEmail/forgotEmail';
import ForgotPassword from './forgotPassword/forgotPassword';
import HelpCentre from './helpCentre/helpCentre';
import { LoginCredentialsWss } from './loginAndRegistrationWss/loginWss';
import * as routes from './manifest';
import NotFound from './notFound/NotFound';
import PrivacyPolicy from './privacyPolicy/privacyPolicy';
import Registration from './registration/Registration';
import TermsAndConditions from './termsAndConditions/termsAndConditions';
import TestPage from './testPage/testPage';
import WelcomePage from './welcomePage/WelcomePage';
import { BrandProps } from '../components/appConfig/AppConfig';
import content from '../content';

function App({ brand }: BrandProps): ReactElement {
  const [passwordValue, setPasswordValue] = useState('');
  const [forgotCredentialSelector, setForgotCredentialSelector] =
    useState(false);
  const [createAccountFAQ, setCreateAccounFAQ] = useState(false);
  const [isAuthenticated, setIsAuthuenticated] = useState(false);
  const [authToken, setAuthToken] = useState('');
  return (
    <Routes>
      <Route
        path={routes.WelcomePage}
        element={
          <ContentProvider value={content({ brand }).welcome}>
            <WelcomePage />
          </ContentProvider>
        }
      />
      <Route
        path="*"
        element={
          <ContentProvider value={content({ brand }).notfound}>
            <NotFound />
          </ContentProvider>
        }
      />
      <Route
        path={routes.Registration}
        element={
          <ContentProvider value={content({ brand }).registration}>
            <Registration
              brand={brand}
              passwordValue={passwordValue}
              setPasswordValue={setPasswordValue}
              setCreateAccountFAQ={setCreateAccounFAQ}
            />
          </ContentProvider>
        }
      />
      <Route
        path={routes.LoginWss}
        element={
          <ContentProvider value={content({ brand }).registration}>
            <LoginCredentialsWss
              forgotCredentialSelector={forgotCredentialSelector}
              setForgotCredentialSelector={setForgotCredentialSelector}
            />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ForgotEmail}
        element={
          <ContentProvider
            value={{
              ...content({ brand }).forgotEmail,
              ...content({ brand }).registration,
            }}
          >
            <ForgotEmail brand={brand} />
          </ContentProvider>
        }
      />
      <Route
        path={routes.ForgotPassword}
        element={
          <ContentProvider value={content({ brand }).forgotPassword}>
            <ForgotPassword
              brand={brand}
              isAuthenticated={isAuthenticated}
              setIsAuthuenticated={setIsAuthuenticated}
              authToken={authToken}
              setAuthToken={setAuthToken}
            />
          </ContentProvider>
        }
      />
      <Route
        path={routes.HelpCentre}
        element={
          <ContentProvider value={content({ brand }).helpCentre}>
            <HelpCentre
              setCreateAccountFAQ={setCreateAccounFAQ}
              createAccountFAQ={createAccountFAQ}
            />
          </ContentProvider>
        }
      />
      <Route
        path={routes.TermsAndConditions}
        element={
          <ContentProvider value={content({ brand }).termsAndConditions}>
            <TermsAndConditions />
          </ContentProvider>
        }
      />
      <Route
        path={routes.CookiePolicy}
        element={
          <ContentProvider value={content({ brand }).cookiePolicy}>
            <CookiePolicy />
          </ContentProvider>
        }
      />
      <Route
        path={routes.PrivacyPolicy}
        element={
          <ContentProvider value={content({ brand }).privacyPolicy}>
            <PrivacyPolicy />
          </ContentProvider>
        }
      />
      <Route path={routes.TestPage} element={<TestPage />} />
      <Route path={routes.DownPage} element={<DownPage />} />
    </Routes>
  );
}

export default App;
