import React, { ReactElement, useEffect, useState } from 'react';

import { Table, TableRow, TableCol, Text } from '@constellation/core';

import { healthcheckAPI } from '../../apis/healthCheck';

export default function APIDownTable(): ReactElement {
  const [healthCheckDbStatus, setHealthCheckDbStatus] = useState('');

  useEffect(() => {
    healthcheckAPI()
      .then((res: any) => {
        setHealthCheckDbStatus(res.response.data.mtaDbApi.code);
      })
      .catch((err) => {
        setHealthCheckDbStatus(err.response.data.mtaDbApi.code);
      });
  }, []);

  return (
    <div>
      <Table
        data-testid="apiTable"
        style={{ backgroundColor: 'black' }}
        headings={['API Name', 'Status Code']}
      >
        <TableRow>
          <TableCol>
            <Text>Health Check DB</Text>
          </TableCol>
          <TableCol>
            <Text>{healthCheckDbStatus}</Text>
          </TableCol>
        </TableRow>
      </Table>
    </div>
  );
}
