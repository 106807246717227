import React, { ReactElement, useState } from 'react';

import {
  Button,
  Container,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Spinner,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  ForgotPasswordContent,
  SetupPasswordProps,
} from './forgotPassword.config';
import { authUserAPI } from '../../apis/authUser';
import { getCustomerAPI } from '../../apis/getCustomerAPI';
import CircleIcon from '../../assets/icons/circleIcon';
import SuccessIcon from '../../assets/icons/successIcon';
import TickIcon from '../../assets/icons/tickIcon';
import {
  StyleIconBox,
  StyledConstrainBox,
  StyledBtnBox,
} from '../../components/appConfig/common.styled';
import DataModal from '../../components/dataModal/dataModal';
import PasswordField from '../../components/passwordField/passwordField';
import {
  passwordPattern,
  passwordCharacters,
  PASSWORD_ADD_PATTERN,
} from '../../constants/REGEX';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { handleUserId } from '../../utils/handleUserId';
import { hasRepeatedCharacters } from '../../utils/hasRepeatedCharacters';
import { isEmptyString } from '../../utils/isEmptyString';
import { redirectURL } from '../../utils/redirectURL';

export default function SetupPassword({
  isForgotPassword,
  handleStepper,
  mtaCustomerId,
  userId,
  handleOnPassword,
  brand,
  authToken,
}: SetupPasswordProps): ReactElement {
  const theme: any = useTheme();
  const {
    passwordConstrain,
    oldPasswordErrorMessage,
    emptyFieldErrorMessage,
    constrainErrorMessage,
    specialCharErrorMessage,
    passwordNotMatchErrorMessage,
    continueLabel,
    createAccountButtonLabel,
    credentialsCreateAccountLabel,
    createPasswordSubText,
    setPasswordFieldLabel,
    createPasswordFieldLabel,
    createPasswordConstrain,
    confirmPasswordFieldLabel,
    dataModelPasswordHeading,
    dataModelPasswordText,
    forgotEmailDataModalButtonText,
    repeatedCharactersErrorMessage,
    oldPassowordApiMessage,
  } = useContent<ForgotPasswordContent>();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [createFieldErrorMsg, setCreateFieldErrorMsg] = useState('');
  const [confirmFieldErrorMsg, setConfirmFieldErrorMsg] = useState('');
  const [showBox, setShowBox] = useState(!isForgotPassword);
  const [showSpinner, setShowSpinner] = useState(false);
  const [email, setEmail] = useState('');
  const [dataArray, setDataArray] = useState(
    passwordConstrain.map((items) => ({
      ...items,
      icon: <CircleIcon colour={theme.color_icon_default_2} />,
    })),
  );
  const { customerData } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const dispatch = useDispatch();
  const dataPattern = new RegExp(PASSWORD_ADD_PATTERN, 'i');
  const passwordValidation = (entries: string): object => {
    const result = {};
    Object.entries(passwordPattern).forEach(([constrain, pattern]) => {
      result[constrain] = new RegExp(pattern).test(entries);
    });
    return result;
  };
  const hanleOnPasswordData = (data: string): void => {
    if (data === oldPassowordApiMessage) {
      setCreateFieldErrorMsg(oldPasswordErrorMessage);
    }
    setShowSpinner(false);
  };
  const handleOnApiCall = (): void => {
    authUserAPI(
      mtaCustomerId,
      {
        password,
        userGroup: ['BLH'],
        userStatus: 'ACTIVE',
        contractId: parseInt(customerData[0].agreementNumber, 10),
        authLiteUUID: handleUserId(userId),
      },
      { Authorization: `Bearer ${authToken}` },
    )
      .then(() => {
        dispatch(DataModelAction(true));
        setShowSpinner(false);
      })
      .catch((error: any) => {
        setShowSpinner(false);
        hanleOnPasswordData(error.response.data[0].errors[0].message);
      });
  };
  const getEmail = () => {
    setShowSpinner(true);
    getCustomerAPI(mtaCustomerId, { Authorization: `Bearer ${authToken}` })
      .then(
        (
          res: {
            emailAddress: string;
          }[],
        ) => {
          setEmail(res[0].emailAddress);
          handleOnApiCall();
        },
      )
      .catch(() => {
        setShowSpinner(false);
      });
  };

  const handleUpdatePassword = (): void => {
    if (isForgotPassword) {
      setShowSpinner(true);
      getEmail();
    }
  };
  const handleNavigateToAuth = (): void => {
    dispatch(DataModelAction(false));
    redirectURL(
      'account-home',
      `${window.appConfig.REACT_APP_AUTH_URL}/`,
      `auth_token=${authToken}&a=1&email=${email}`,
    );
  };
  const handleFieldName = (txtFieldName: string): boolean =>
    txtFieldName === 'createPassword';

  const handleOnPCSetErrMsg = (value: string, name: string): void => {
    if (handleFieldName(name)) {
      setCreateFieldErrorMsg(value);
    } else setConfirmFieldErrorMsg(value);
  };
  const handlePCChange = (event: {
    target: { value: any; name: any };
  }): void => {
    const { value, name } = event.target;
    if (handleFieldName(name)) {
      setDataArray((item) =>
        item.map((icon) => ({
          ...icon,
          icon: passwordValidation(value)[icon.validEntry] ? (
            <TickIcon colour={theme.color_icon_success} />
          ) : (
            <CircleIcon colour={theme.color_icon_default_2} />
          ),
        })),
      );
      setShowBox(true);
      setPassword(value);
      if (!isForgotPassword) {
        handleOnPassword(value);
      }
    } else {
      setConfirmPassword(value);
    }
    handleOnPCSetErrMsg('', name);
  };
  const handleOnCreateFieldErrMsg = (value: string, name: string): void => {
    const isPatternMatched = Object.values(passwordValidation(value)).some(
      (isValidPassword) => isValidPassword === false,
    );
    if (isEmptyString(value)) {
      handleOnPCSetErrMsg(emptyFieldErrorMessage, name);
    } else if (dataPattern.test(value))
      handleOnPCSetErrMsg(constrainErrorMessage, name);
    else if (hasRepeatedCharacters(value))
      handleOnPCSetErrMsg(repeatedCharactersErrorMessage, name);
    else if (isPatternMatched || !new RegExp(passwordCharacters).test(value)) {
      handleOnPCSetErrMsg(
        new RegExp(passwordCharacters).test(value)
          ? constrainErrorMessage
          : specialCharErrorMessage,
        name,
      );
    } else if (isEmptyString(createFieldErrorMsg)) {
      setShowBox(false);
    }
  };
  const handleOnConfirmFieldErrMsg = (value: string, name: string): void => {
    if (isEmptyString(value)) {
      handleOnPCSetErrMsg(emptyFieldErrorMessage, name);
    } else if (value !== password) {
      handleOnPCSetErrMsg(passwordNotMatchErrorMessage, name);
    }
  };
  const handleBlurEvt = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ): void => {
    const { value, name } = event.target;
    if (handleFieldName(name)) {
      handleOnCreateFieldErrMsg(value, name);
    } else {
      handleOnConfirmFieldErrMsg(value, name);
    }
  };
  const handleOnEmptyField = (): boolean => {
    if (isEmptyString(password)) {
      setCreateFieldErrorMsg(emptyFieldErrorMessage);
    }
    if (isEmptyString(confirmPassword)) {
      setConfirmFieldErrorMsg(emptyFieldErrorMessage);
    } else if (confirmPassword !== password) {
      setConfirmFieldErrorMsg(passwordNotMatchErrorMessage);
    } else if (
      isEmptyString(confirmFieldErrorMsg) &&
      isEmptyString(createFieldErrorMsg)
    ) {
      return true;
    }
    return false;
  };
  const renderSpinner = (): ReactElement => (
    <Container marginBottom="07" marginTop="07" padding="none">
      <ContentGroup marginBottom="07" marginTop="07">
        <Spinner />
      </ContentGroup>
    </Container>
  );
  const handleOnCreateAccount = (): void => {
    if (handleOnEmptyField()) {
      handleUpdatePassword();
      if (!isForgotPassword) handleStepper();
    }
  };
  const renderButtonGroup = (): ReactElement => (
    <StyledBtnBox>
      <Button
        data-testid="createAccbtn"
        onClick={handleOnCreateAccount}
        data-qa-id={dataQaIds.loginAndRegistration.createAccBtn}
      >
        {isForgotPassword ? continueLabel : createAccountButtonLabel}
      </Button>
    </StyledBtnBox>
  );

  return (
    <GridItem>
      {showSpinner ? (
        renderSpinner()
      ) : (
        <>
          {!isForgotPassword && (
            <>
              <Heading size="s4" marginBottom="02" aria-level={3}>
                {credentialsCreateAccountLabel}
              </Heading>
              <Text size="s2">{createPasswordSubText}</Text>
            </>
          )}
          <Grid>
            <GridItem lg={8} md={8}>
              <PasswordField
                label={
                  isForgotPassword
                    ? setPasswordFieldLabel
                    : createPasswordFieldLabel
                }
                name="createPassword"
                testId="createPassword"
                onChange={handlePCChange}
                onBlur={handleBlurEvt}
                inputValue={password}
                error={createFieldErrorMsg}
                inputWidth="fluid"
                fieldMarginTop={isForgotPassword ? 'none' : '05'}
                dataQaId={dataQaIds.loginAndRegistration.createPasswordField}
                fieldMarginBottom={showBox ? 'none' : '05'}
              />
              {showBox && (
                <StyledConstrainBox
                  bgColor="none"
                  marginBottom="05"
                  marginTop="03"
                  data-testid="constrainBox"
                >
                  <ContentGroup marginBottom="05">
                    <Text size="s2">{createPasswordConstrain}</Text>
                  </ContentGroup>
                  {dataArray.map((items, index) => (
                    <StyleIconBox key={`constrain_${index + 1}`}>
                      {items.icon}
                      <Text size="s2" marginLeft="04">
                        {dataArray[index].constrain}
                      </Text>
                    </StyleIconBox>
                  ))}
                </StyledConstrainBox>
              )}
              <PasswordField
                label={confirmPasswordFieldLabel}
                name="confirmPassword"
                testId="confirmPassword"
                onBlur={handleBlurEvt}
                onChange={handlePCChange}
                error={confirmFieldErrorMsg}
                inputValue={confirmPassword}
                inputWidth="fluid"
                dataQaId={dataQaIds.loginAndRegistration.confirmPasswordField}
                fieldMarginBottom="07"
              />
            </GridItem>
          </Grid>
          {renderButtonGroup()}
          {isForgotPassword && (
            <DataModal
              icon={<SuccessIcon colour={theme.color_text_default_1} />}
              onClick={handleNavigateToAuth}
              onClose={() => dispatch(DataModelAction(false))}
              heading={dataModelPasswordHeading}
              description={dataModelPasswordText}
              buttonTxt={forgotEmailDataModalButtonText}
              dataQaId={{
                btn: dataQaIds.loginAndRegistration.loginBtn,
              }}
              brand={brand}
            />
          )}
        </>
      )}
    </GridItem>
  );
}
