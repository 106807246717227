import React, { ReactElement, useState, useEffect, useRef } from 'react';

import {
  ContentGroup,
  Heading,
  GridItem,
  Grid,
  Text,
  Spinner,
} from '@constellation/core';
import { Warning } from '@constellation/core/icons';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch } from 'react-redux';

import { LoginWssProps } from './LoginAndRegistrationWss.config';
import {
  StyledDetailsBox,
  StyledFieldBox,
  StyledLinkButtonBox,
  StyledWssButton,
  StyledLinkButtonContainer,
  StyledHrWss,
  StyledBox,
  StyledLinkBGridItem,
  StyledNotification,
} from './LoginAndRegistrationWss.styled';
import { MultiAgreement } from './multiAgreementWss';
import { authLoginApi } from '../../apis/authLoginApi';
import { authZAPI } from '../../apis/authZApi';
import { authZMockApi } from '../../apis/authZMockApi';
import { authZTokenAPI } from '../../apis/authZTokenApi';
import { isWssMtaApi } from '../../apis/isWssMtaApi';
import EmailField from '../../components/emailField/emailField';
import LinkButton from '../../components/linkButton/LinkButton';
import PasswordField from '../../components/passwordField/passwordField';
import { REGEX } from '../../constants/REGEX';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  CustomerWssDataAction,
  CustomerMtaDataAction,
  UpdateDOBPostcodeAction,
  DOBPostcodeContractsDataAction,
} from '../../store/action/registration.action';
import { UpdateEmailAPIAction } from '../../store/action/updateEmail.action';
import { challenge, verifier } from '../../utils/codeGenerator';
import { dataLayer } from '../../utils/dataLayer';
import { isPre } from '../../utils/handleEnvVariables';
import { isEmptyString } from '../../utils/isEmptyString';
import { redirectURL } from '../../utils/redirectURL';
import { ForgotCredentialSelector } from '../forgotPasswordWss/forgotEmailOrPassSelector';
import * as routes from '../manifest';
import {
  ApiAuthResponse,
  RegistrationContent,
} from '../registration/Registration.config';

export function LoginCredentialsWss({
  forgotCredentialSelector,
  setForgotCredentialSelector,
}: LoginWssProps): ReactElement {
  const {
    registrationLabel,
    passwordLabel,
    emailAddressLabel,
    forgotEmailOrPassLink,
    notRegisteredLabel,
    needHelpText,
    loginFindAccountError,
    loginHelpLabel,
    goBackLink,
    emailErrorMessage,
    emptyFieldErrorMessage,
  } = useContent<RegistrationContent>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailValue = useRef({} as HTMLInputElement);
  const passwordValue = useRef({} as HTMLInputElement);
  const [isLoginError, setIsLoginError] = useState(false);
  const [isMultiContract, setIsMultiContract] = useState({
    isMTA: false,
    isWSS: false,
  });
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [isDataFound, setIsDataFound] = useState(false);
  const [email, setEmail] = useState('');
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const [passwordErrMsg, setPasswordErrMsg] = useState('');
  const emailPattern = new RegExp(REGEX.EMAIL_PATTERN);
  useEffect(() => {
    window.sessionStorage.removeItem('email');
    window.sessionStorage.removeItem('auth_token');
    window.sessionStorage.removeItem('contractNumber');
    window.appConfig.JOURNEY_NAME = 'Login';
    window.appConfig.JOURNEY_STEP_NAME = 'Login';
    window.appConfig.PAGE_ROLE = 'Servicing';
    dataLayer();
  }, []);

  const handleNavigate = () => {
    navigate(routes.RegistrationWss);
  };
  const handleMultiAgreement = (value: boolean): void => {
    setIsMultiContract({ isMTA: value, isWSS: value });
    setIsDataFound(!value);
  };
  useEffect(() => {
    dispatch(DataModelAction(false));
    dispatch(
      UpdateDOBPostcodeAction('', { day: '', month: '', year: '' }, true),
    );
    dispatch(
      DOBPostcodeContractsDataAction([], {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: false,
      }),
    );
    dispatch(UpdateEmailAPIAction(false));
  }, [dispatch]);

  const handleOnEmailSetErrMsg = (value: string): void => setEmailErrMsg(value);
  const handleOnLink = (): void => {
    if (forgotCredentialSelector) {
      setForgotCredentialSelector(false);
    } else if (isMultiContract.isMTA || isMultiContract.isWSS) {
      setIsMultiContract({ isMTA: false, isWSS: false });
    } else {
      handleNavigate();
    }
  };

  const handleOnPasswordField = (): boolean => {
    if (isEmptyString(passwordValue.current.value)) {
      setPasswordErrMsg(emptyFieldErrorMessage);
      return true;
    }
    setPasswordErrMsg('');
    return false;
  };
  const handleOnEmailFields = (): boolean => {
    if (isEmptyString(emailValue.current.value.trim())) {
      setEmailErrMsg(emailErrorMessage);
      return true;
    }
    if (
      !isEmptyString(emailValue.current.value.trim()) &&
      !emailPattern.test(emailValue.current.value.trim())
    ) {
      handleOnEmailSetErrMsg(emailErrorMessage);
      return true;
    }
    handleOnEmailSetErrMsg('');
    return false;
  };
  const handleOnEmptyFields = (): boolean => {
    if (isEmptyString(emailValue.current.value.trim())) {
      setEmailErrMsg(emailErrorMessage);
    }
    if (
      !isEmptyString(emailValue.current.value.trim()) &&
      !emailPattern.test(emailValue.current.value.trim())
    ) {
      handleOnEmailSetErrMsg(emailErrorMessage);
    }
    if (isEmptyString(passwordValue.current.value)) {
      setPasswordErrMsg(emptyFieldErrorMessage);
    } else if (isEmptyString(emailErrMsg) && isEmptyString(passwordErrMsg)) {
      return true;
    }
    return false;
  };
  const handleMultiAgreementResponse = (data: any): void => {
    if (
      Object.keys(data).includes('isMTA') &&
      Object.keys(data).includes('isWSS')
    ) {
      setIsMultiContract({ isMTA: true, isWSS: true });
      dispatch(
        CustomerMtaDataAction(data.isMTA as ApiAuthResponse[], {
          isMTA: true,
          isWSS: true,
        }),
      );
      dispatch(
        CustomerWssDataAction(data.isWSS as ApiAuthResponse[], {
          isWSS: true,
          isMTA: true,
        }),
      );
    } else if (Object.keys(data).includes('isMTA')) {
      setIsMultiContract({ isMTA: true, isWSS: false });
      dispatch(
        CustomerMtaDataAction(data.isMTA as ApiAuthResponse[], {
          isMTA: true,
          isWSS: false,
        }),
      );
    } else if (Object.keys(data).includes('isWSS')) {
      setIsMultiContract({ isMTA: false, isWSS: true });
      dispatch(
        CustomerWssDataAction(data.isWSS as ApiAuthResponse[], {
          isWSS: true,
          isMTA: false,
        }),
      );
    }
  };
  const handleMultiContractCondition = (res: any) =>
    Object.keys(res).length > 1 ||
    res.isMTA?.length > 1 ||
    res.isWSS?.length > 1;
  const handleApiResponse = (res: any) => {
    if (handleMultiContractCondition(res)) {
      handleMultiAgreementResponse(res);
    } else if (Object.keys(res).length === 1) {
      if (Object.keys(res).includes('isMTA')) {
        setIsDataFound(true);
      } else if (Object.keys(res).includes('isWSS')) {
        window.location.href = res.isWSS[0].redirectLink;
      }
    }
  };
  const handleCheckAccounts = () => {
    setEmail(emailValue.current.value.trim());
    if (!isDataFound && !handleOnEmailFields()) {
      setIsApiCalled(true);
      isWssMtaApi(emailValue.current.value.trim())
        .then((res: any) => {
          handleApiResponse(res);
          setIsApiCalled(false);
        })
        .catch(() => {
          setIsApiCalled(false);
        });
    }
  };

  const handleNeedHelpNavigate = (): void => {
    navigate(routes.HelpCentre);
  };

  const authorizeLoginAndRedirect = (response) => {
    authZAPI(response.accessToken, challenge)
      .then((res: { authorization_code: string }) => {
        authZTokenAPI(res.authorization_code, verifier)
          .then((auth: { access_token: string; refresh_token: string }) => {
            redirectURL(
              'account-home',
              response.redirectLink,
              `auth_token=${auth.access_token}&a=1&email=${emailValue.current.value}`,
            );
          })
          .catch(() => {
            setIsLoginError(true);
          });
      })
      .catch(() => {
        setIsLoginError(true);
      });
  };

  const handleOnLogin = () => {
    if (handleOnEmptyFields()) {
      authLoginApi(emailValue.current.value, passwordValue.current.value)
        .then(
          (res: {
            redirectLink: string;
            accessToken: string;
            authSessionId: string;
          }) => {
            setIsLoginError(false);
            if (isPre) {
              const token = JSON.parse(atob(res.accessToken.split('.')[1]));
              authZMockApi(token.sub)
                .then((response: any) => {
                  redirectURL(
                    'account-home',
                    res.redirectLink,
                    `auth_token=${response}&a=1&email=${emailValue.current.value}`,
                  );
                })
                .catch(() => {
                  setIsLoginError(true);
                });
            } else authorizeLoginAndRedirect(res);
          },
        )
        .catch(() => {
          setIsLoginError(true);
        });
    }
  };
  const renderHeading = (): string => {
    return forgotCredentialSelector ? loginHelpLabel : registrationLabel;
  };
  const renderLink = (): string => {
    return forgotCredentialSelector ||
      isMultiContract.isMTA ||
      isMultiContract.isWSS
      ? goBackLink
      : notRegisteredLabel;
  };
  const handleOnForgotEmailOrPass = (): void => {
    setIsLoginError(false);
    setForgotCredentialSelector(true);
  };

  const renderLoginCredentials = (): ReactElement =>
    isApiCalled ? (
      <ContentGroup marginBottom="09" marginTop="09">
        <Spinner />
      </ContentGroup>
    ) : (
      <>
        <ContentGroup marginBottom="none" marginTop="05">
          <StyledFieldBox>
            <EmailField
              name="email"
              label={emailAddressLabel}
              inputWidth="fluid"
              inputRef={emailValue}
              fieldMarginBottom="05"
              testId="emailField"
              onBlur={handleCheckAccounts}
              inputValue={email}
              error={emailErrMsg}
              onChange={() => {
                handleOnEmailSetErrMsg('');
                setIsDataFound(false);
              }}
            />
          </StyledFieldBox>
        </ContentGroup>

        <ContentGroup marginBottom="none" marginTop="none">
          <StyledFieldBox>
            <PasswordField
              fieldMarginBottom="05"
              name="password"
              label={passwordLabel}
              inputWidth="fluid"
              inputRef={passwordValue}
              testId="passwordField"
              onBlur={handleOnPasswordField}
              onChange={() => setPasswordErrMsg('')}
              error={passwordErrMsg}
            />
          </StyledFieldBox>
        </ContentGroup>
        <StyledLinkButtonContainer alignX="center">
          <LinkButton
            handleButtonClick={handleOnForgotEmailOrPass}
            testId="forgotEmailOrPassBtn"
          >
            {forgotEmailOrPassLink}
          </LinkButton>
        </StyledLinkButtonContainer>
        <ContentGroup marginBottom="04">
          <StyledWssButton
            width="fluid"
            onClick={handleOnLogin}
            data-testid="loginBtn"
          >
            {registrationLabel}
          </StyledWssButton>
        </ContentGroup>
      </>
    );
  const renderContent = (): ReactElement => {
    return (
      <>
        {isLoginError && (
          <StyledNotification
            marginBottom="none"
            icon={<Warning />}
            sentiment="warning"
            marginTop="04"
            data-testid="login-error"
          >
            <ContentGroup marginBottom="02">
              <Text size="s1">{loginFindAccountError}</Text>
            </ContentGroup>
          </StyledNotification>
        )}
        {forgotCredentialSelector ? (
          <ForgotCredentialSelector />
        ) : (
          renderLoginCredentials()
        )}
      </>
    );
  };
  return (
    <GridItem lg={4.4} md={6}>
      <StyledBox marginBottom="07" marginTop="07">
        <StyledDetailsBox marginBottom="none">
          <Heading size="s4">{renderHeading()}</Heading>
          <StyledHrWss marginBottom="05" marginTop="05" />{' '}
          {isMultiContract.isMTA || isMultiContract.isWSS ? (
            <MultiAgreement
              isMultiAgreementMTA={isMultiContract.isMTA}
              isMultiAgreementWSS={isMultiContract.isWSS}
              handleMultiAgreement={handleMultiAgreement}
            />
          ) : (
            renderContent()
          )}
        </StyledDetailsBox>
        <StyledHrWss marginBottom="none" marginTop="none" />{' '}
        <Grid alignX="justify" alignY="center">
          <StyledLinkBGridItem lg={6} md={6} sm={6}>
            <StyledLinkButtonBox alignX="center">
              <LinkButton
                handleButtonClick={handleOnLink}
                testId="notRegisteredLink"
              >
                {renderLink()}
              </LinkButton>
            </StyledLinkButtonBox>
          </StyledLinkBGridItem>
          <GridItem lg={6} md={6} sm={6}>
            <StyledLinkButtonBox alignX="center">
              <LinkButton
                handleButtonClick={handleNeedHelpNavigate}
                testId="needHelpLink"
              >
                {needHelpText}
              </LinkButton>
            </StyledLinkButtonBox>
          </GridItem>
        </Grid>
      </StyledBox>
    </GridItem>
  );
}
