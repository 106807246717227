import { AppContent } from '../AppContent';

const privacyPolicy: AppContent['privacyPolicy'] = {
  PrivacyNoticeHeading: 'Privacy Notice',
  LastUpdatedHeading: 'Last updated May 2021',
  content: 'This page is best viewed on a desktop.',
  PersonalInformationHeading: 'How we use your personal information',
  PersonalInformationContent:
    'Your information will be held by Black Horse Ltd and Black Horse Offshore Ltd, which are part of Lloyds Banking Group. This privacy notice is to let you know how companies within the Group promise to look after your personal information. This includes what you tell us about yourself, what we learn by having you as a customer, and the choices you give us about what marketing you want us to send you. This notice also tells you about your privacy rights and how the law protects you.',
  PrivacyPromiseHeading: 'Our Privacy Promise',
  PromiseSubHeading: 'We promise:',
  PromiseTxt:
    'To keep your personal information safe and private.// Not to sell your personal information.//To give you ways to manage and review your marketing choices at any time.',
  PersonalInfoHeading: 'PERSONAL INFORMATION AND THE LAW',
  WhoWeAretxt: 'Who we are ',
  SectionContent:
    'This section tells you who we are, what your personal information is, and how we get it. It explains how the law protects you by controlling what is allowed to happen to it.',
  SectionTxt: `1. Who we are// 2. How the law protects you//3. Groups of personal information//4. Where we collect personal information from//5. How long we keep your personal information//6. If you choose not to give personal information//7. Cookies and similar tracking technologies`,
  SectionSubTxtHeading: 'How personal information is used',
  SectionSubTxt: `This tells you who we share personal information with. It explains what it's used for in marketing, to provide credit and to combat fraud - and how this can affect you.//8. Who we share your personal information with//9. How we work out what marketing you receive//10. How we use your personal information to make automated decisions//11. Credit Reference Agencies (CRAs)//12. Fraud Prevention Agencies (FPAs)//13. Sending data outside of the UK, Isle of Man, Channel Islands and EEA.`,
  TheControlHeading: 'The control you have',
  TheControlTxt:
    'This section explains your data privacy rights and how you can action them. It also covers how to contact us or the data privacy regulator, or how to make a complaint.',
  TheControlSubTxt: `14. Your data privacy rights//
  15. How to contact us`,
  PersonalInfoSubTxt: `This section gives you the legal name of the company who holds your personal information - known as the 'legal entity' - and tells you how you can get in touch with us.`,
  PeronsalInfoService: `Lloyds Banking Group is made up of a mix of companies, set up on different legal entities. We'll let you know which you have a relationship with, when you take out a product or service with us.`,
  PeronsalInfoFindOutTxt: `Find out more about us at www.lloydsbankinggroup.com.`,
  ContactingUsHeading: 'Contacting us about data privacy',
  PrivacyNoticeTxt:
    'Please use these details to contact us about any of the topics set out in this Privacy notice.',
  AnyQuestionsTxt:
    'If you have any questions, or want more details about how we use your personal information, you can call us on 0344 824 8888 (+44 344 824 8888 from outside the UK). Calls may be monitored or recorded.',
  HearingSpeechImpairmentText: `If you have a hearing or speech impairment you can also call us using Relay UK. If you use a textphone, just add 18001 before the number you're calling. On a smartphone, tablet or computer, you can call through the Relay UK app. There's more information on the Relay UK help pages.`,
  BSLTxt: `If you're Deaf and a BSL user, you can talk to us live through a BSL interpreter online with our BSL SignVideo service.`,
  MainContactTxt: `These are our main contact details. Some sections of this notice have special contact details that you can use instead.`,
  NotSatisfiedTxt: `If you are not satisfied with our response, you can contact our Group Data Protection Officer.`,
  LawHeading: 'How the law protects you',
  LegalTxt: `This section sets out the legal reasons we rely on, for each of the ways we may use your personal information.`,
  PrivacyPromiseTxt: `As well as our Privacy Promise, your privacy is protected by law. This section explains how that works.`,
  DataProtectionTxt: `Data Protection law says that we are allowed to use personal information only if we have a proper reason to do so. This includes sharing it outside Lloyds Banking Group. The law says we must have one or more of these reasons:`,
  DataProtectionSubTxt: `To fulfil a contract we have with you, or//When it is our legal duty, or//When it is in our legitimate interest, or//When you consent to it, or//When it is in the public interest.`,
  BussinessTxt: `When we have a business or commercial reason of our own to use your information, this is called a 'legitimate interest'. We will tell you what that is, if we are going to rely on it as the reason for using your data. Even then, it must not unfairly go against your interests.`,
  BussinessSubTxt: `The law and other regulations treat some types of sensitive personal information as special. This includes information about racial or ethnic origin, sexual orientation, religious beliefs, trade union membership, health data, and criminal records. We will not collect or use these types of data without your consent unless the law allows us to do so. If we do, it will only be when it is necessary:`,
  SubstantialSubTxt: `for reasons of substantial public interest, or//
  to establish, exercise or defend legal claims.`,
  ReasonToRely: `Here is a list of all the ways that we may use your personal information, and which of the reasons we rely on to do so. This is also where we tell you what our legitimate interests are.`,
  ServingAsCustomer: `Serving you as a customer`,
  UsePersonalInfoTxt: `What we use your personal information for:`,
  UsePersonalInfoSubTxt: `To manage our relationship with you or your business.//To develop and carry out marketing activities.//To study how our customers use products and services from us and other organisations.//To communicate with you about our products and services.//To develop and manage our brands, products and services.`,
  OurReasonHeading: `Our reasons`,
  OurReasonSubTxt: `Your consent.//Fulfilling contracts.//Our legitimate interests.//Our legal duty.`,
  OurLegitimateInterestsHeading: 'Our legitimate interests',
  OurLegitimateIntrest:
    'Keeping our records up to date, working out which of our products and services may interest you and telling you about them.//Developing products and services, and what we charge for them.//Defining types of customers for new products or services.//Seeking your consent when we need it to contact you.//Being efficient about how we fulfil our legal duties.//Managing competitions and promotions.',
  BusinessImprovementHeading: 'Business improvement',
  BusinessImprovementTxt: `To test new products.//To manage how we work with other companies that provide services to us and our customers.//To develop new ways to meet our customers’ needs and to grow our business.`,
  OurReasonContracts: `Fulfilling contracts.//Our legitimate interests.//Our legal duty.`,
  OurLegitimateProdServices: `Developing products and services, and what we charge for them.//Defining types of customers for new products or services.//Being efficient about how we fulfil our legal and contractual duties.`,
  ManagingOperations: 'Managing our operations',
  UsePeronsalInfoFindOutTxt: `To deliver our products and services.//To make and manage customer payments.//To manage fees, charges and interest due on customer accounts.//To collect and recover money that is owed to us.//To manage and provide treasury and investment products and services.`,
  OurLegitimateEfficientTxt: `Being efficient about how we fulfil our legal and contractual duties.//Complying with rules and guidance from regulators.`,
  ManagingSecurity: 'Managing security, risk and crime prevention',
  DetectTxt:
    'To detect, investigate, report, and seek to prevent financial crime.//To manage risk for us and our customers.//To obey laws and regulations that apply to us.//To respond to complaints and seek to resolve them.',
  LegitimateTxt: `Developing and improving how we deal with financial crime, as well as doing our legal duties in this respect.//Complying with regulations that apply to us.//Being efficient about how we fulfil our legal and contractual duties.`,
  BussinessMangement: 'Business management',
  BussinessMgmtSubtext: `To exercise our rights set out in agreements or contracts.//To run our business in an efficient and proper way. This includes managing our financial position, business capability, planning, communications, corporate governance, and audit.`,
  LegitimateInterestTxt: `Complying with regulations that apply to us.//Being efficient about how we fulfil our legal and contractual duties.`,
  ProcessingSpecialTxt:
    'For processing special categories of personal information',
  SubstantialTxt: `Substantial public interest//Responding to regulatory requirements//Legal claims`,
  CriminalRecordTxt: `Using criminal records data to help prevent, detect, and prosecute unlawful acts and fraudulent behaviour.//Using criminal and health information as needed to provide insurance products.//Showing whether we have assessed your situation in the right way.//Passing information to the regulator as needed to allow investigation into whether we have acted in the right way.//Using any special categories of data as needed to establish, exercise or defend legal claims.`,
  GroupsOfPersonalInfo: `Groups of personal information`,
  GroupsPersonalTxt: `This explains what all the different types of personal information mean, that are covered by data protection law.`,
  GroupsSubTxt: `We use many different kinds of personal information. They are grouped together like this. The groups are all listed here so that you can see what we may know about you, even if we simply get it from seeing which shops you buy things in. We don't use all this data in the same way. Some of it is useful for marketing, or for providing services to you. But some of it is private and sensitive and we treat it that way.`,
  PersonalInfoType: `Type of personal information:`,
  Consent: `Consent`,
  Description: 'Description',
  DescriptionSubTxt: `Telling you that we need your consent to process special categories of personal data, when that is what we rely on for doing so.`,
  FinancialTxt: `What they do//Financial`,
  MychoiceTxt: `My choices`,
  YourPositionHistory: `Your financial position, status and history.`,
  PersonalType: `Type of personal information`,
  ContactHeading: 'Contact',
  ContactTxt: `Your name, where you live and how to contact you.`,
  SocioDemographic: `Socio-Demographic`,
  DetailsSubTxt: `
    Description//
    This includes details about your work or profession, nationality, education and where you fit into general social or income groupings.//
    Type of personal information//Transactional//
    Description//
    Details about payments to and from your accounts, and insurance claims you make.//
    Type of personal information://
    Contractual//
    Description//
    Details about the products or services we provide to you.//
    Type of personal information://
    Locational//
    Description//
    Data we get about where you are. This may come from your mobile phone or the place where you connect a computer to the internet. It can also include shops where you buy something with your card.//
    Type of personal information://
    Behavioural//
    Description//
    Details about how you use products and services from us and other organisations.//
    Type of personal information://
    Communications//
    Description//
    What we learn about you from letters and emails you write to us, and conversations between us.//
    Type of personal information://
    Technical//
    Description//
    Details on the devices and technology you use.//
    Type of personal information://
    Social Relationships//
    Description//
    Your family, friends, and other relationships.//
    Type of personal information://
    Open Data and Public Records//
    Description//
    Details about you that are in public records such as the Electoral Register, and information about you that is openly available on the internet.//
    Type of personal information://
    Usage Data//
    Description//
    Other data about how you use your products and services.//
    Type of personal information://
    Documentary Data//
    Description//
    Details about you that are stored in documents in different formats, or copies of them. This could include things like your passport, driving licence, or birth certificate.//
    Type of personal information://
    Special Category Data//
    Description//
    The law and other regulations treat some types of personal information as special. We will only collect and use these if the law allows us to do so://
`,
  DescriptionSubtext: `Racial or ethnic origin // Religious, political or philosophical beliefs//Trade union membership//Genetic and bio-metric data//Health data//Lifestyle information, including data related to sex life or sexual orientation//Criminal records of convictions and offences//Allegations of criminal offences.`,
  SpecialTypes: `You can read how we may use special types of data in the table 'How the law protects you'.`,
  TypeOfPersonalInfo: `Type of personal information://
    Consents//
    Description//
    Any permissions, consents, or preferences that you give us. This includes things like how you want us to contact you, whether you get paper statements, or if you prefer large-print formats.//
    Type of personal information://
    National Identifier//
    Description//
    A number or code given to you by a government to identify who you are, such as a National Insurance or social security number, or Tax Identification Number (TIN).//
    Where we collect personal information from//
    This section lists all the places where we get data that counts as part of your personal information.//
    We may collect personal information about you (or your business) from other Lloyds Banking Group companies and any of these sources://
  `,
  DataHeading: `Data you give to us`,
  DataTxt: `This covers data given by you as well as data provided by people linked with you or your business's product or service, or people working on your behalf. This could mean a joint account holder, trustee, or fellow company director.`,
  DataSubTxt: `When you apply for our products and services//
    When you talk to us on the phone or in branch, including recorded calls and notes we make//
    When you use our websites, mobile device apps, web chat or SignVideo services//
    In emails, letters and mobile text//
    In insurance claims or other documents//
    In financial reviews and interviews//
    In customer surveys//
    If you take part in our competitions or promotions.//
    Data we collect when you use our services`,
  AccountActivity: `This covers two things: details about how and where you access our services, and account activity that is shown on your statement.`,
  AccountActivitySubText: `Payment and transaction data//
    This includes the amount, frequency, type, location, origin and recipients.//
    If you borrow money, it also includes details of repayments and whether they are made on time and in full//
    Profile and usage data`,
  AccServicesTxt: `This includes the security details you create and use to connect to our services. It also includes your settings and marketing choices. We also gather data from the devices you use (such as computers and mobile phones) to connect to our internet, mobile and telephone banking services.`,
  AssServicesSubTxt: `We may also use cookies and similar tracking technologies to collect data from our websites or mobile apps and when you receive, open or respond to emails we send you. You can find out more about this in our Cookies policy.`,
  OutsideDataTxt: `Data from outside organisations`,
  OutsideDataSubTxt: `Companies that introduce you to us (if you use a price comparison website, a broker, or a store or car dealership offering finance deals through us). Companies that introduce you to us (such as a store or car dealership offering finance deals through us)//
    Financial advisers//
    Credit card providers like Visa and Mastercard//
    Credit reference agencies such as TransUnion, Equifax and Experian//
    Insurers//
    Retailers//
    Comparison websites//
    Social networks and other technology providers (for instance, when you click on one of our ads)//
    Fraud prevention agencies//
    Other financial services companies (to fulfil a payment or other service as part of a contract with you; or to help prevent, detect and prosecute unlawful acts and fraudulent behaviour)//
    Employers (for instance, to get a reference if you apply for a mortgage)//
    Payroll service providers//
    Land agents (such as firms who do property valuations for mortgages)//
    Public information sources such as the Electoral Register or Companies House//
    Products which have a loyalty scheme//
    Agents, suppliers, sub-contractors and advisers//
    These can be types of firm we use to help us run accounts and services. They can also be specialist companies who advise us on ways to develop and improve our business.//
    Market researchers//
    These firms may get in touch with you on our behalf to ask you for your opinions and feedback. Sometimes these firms will combine what you tell them with data from other sources to study it. The will use this to produce reports and advice that help us understand our customers’ point of view, so that we can improve the way we work as a business.//
    Firms providing data services (We receive reports that we use to better understand our customers and look for general patterns and trends.)//
    Medical professionals (for some insurance products, we may ask your GP or other medical practitioner to send us a report. We will only do this if we get your consent first)//
    Government and law enforcement agencies.
  `,
  KeepYourPersonalInfo: `How long we keep your personal information`,
  InformationTxt: `This section explains how long we may keep your information for and why`,
  InformationSubTxt: `We will keep your personal information for as long as you are a customer of Lloyds Banking Group.`,
  InfoReasonTxt: `We may keep your data for up to 10 years after you stop being a customer. The reasons we may do this are:`,
  InfoReasonSubTxt: `To respond to a question or complaint, or to show whether we gave you fair treatment.//
    To study customer data as part of our own internal research.//
    To obey rules that apply to us about keeping records. For example, the Money Laundering Regulations require us to retain certain data for a minimum of 5 and a maximum of 10 years.`,
  CustomerTxt: `We may also keep your data for longer than 10 years if we cannot delete it for legal, regulatory or technical reasons. As an example, we have to hold pension transfer information indefinitely; and in cases of subsidence* we will keep insurance claims data for up to 15 years after you stop being a customer.`,
  CustomerSubTxt: `We will only use your personal information for those purposes and will make sure that your privacy is protected.`,
  SubsidenceTxt: `* Subsidence is when a building becomes unsafe or damaged by ground sinking around it.`,
  NotToGiveInfo: `If you choose not to give personal information`,
  ChooseInfoTxt: `You can choose not to give us personal information. In this section we explain the effects this may have.`,
  ChooseInfoSubTxt: `We may need to collect personal information by law, or to enter into or fulfil a contract we have with you.`,
  ChooseInfoDelayTxt: `If you choose not to give us this personal information, it may delay or prevent us from fulfilling our contract with you, or doing what we must do by law. It may also mean that we cannot run your accounts or policies. It could mean that we cancel a product or service you have with us.`,
  ChooseInfoContractTxt: `We sometimes ask for information that is useful, but not required by law or a contract. We will make this clear when we ask for it. You do not have to give us these extra details and it won't affect the products or services you have with us.`,
  CookiesTxt: `Cookies and similar tracking technologies`,
  HowToUseAppEmailTxt: `This section explains how we use data on our websites, apps and emails.`,
  HowToUseAppEmailSubTxt: `We may use cookies and similar tracking technologies on our websites and apps, and in emails we send you.`,
  Cookies: ` Cookies`,
  CookiesParagraph: `Cookies are small computer files that get sent down to your PC, tablet or mobile phone by websites when you visit them. They stay on your device and get sent back to the website they came from, when you go there again. Cookies store information about your visits to that website, such as your choices and other details. Some of this data does not contain personal details about you or your business, but it is still protected by this Privacy notice.`,
  EmailTracking: `Email Tracking`,
  EmailTrackingSubTxt: `We track emails to help us improve the communications we send. We use small images called pixels within our emails to tell us things like whether you opened the email, how many times and the device used. We may also set a cookie to find out if you clicked on any links in the email.`,
  CookiePolicy: `To find out more about how we use cookies, please see our Cookies Policy:`,
  CookiePolicySubTxt: `<a href="https://www.blackhorse.co.uk/cookies-policy" target="_blank">http://www.blackhorse.co.uk/cookies-policy</a> or <a href="https://www.blackhorseoffshore.co.uk/cookies/" target="_blank">http://www.blackhorseoffshore.co.uk/cookies/</a>`,
  TheControlYouHaveTxt: `The control you have`,
  ComplainHeading: `How to complain`,
  ComplaintDataTxt: `This section gives details of how to contact us to make a complaint about data privacy. It also shows you where you can get in touch with the government regulator.`,
  ComplaintDataSubTxt: `Please let us know if you are unhappy with how we have used your personal information.`,
  ComplaintRegulatorTxt: `You also have the right to complain to the regulator, and to lodge an appeal if you are not happy with the outcome of a complaint.`,
  PrivInformationCommissionTxt: `In the UK this is the Information Commissioner’s Office. Find out on their website how to report a concern <a href="https://ico.org.uk/concerns/" target="_blank">https://ico.org.uk/concerns/</a>.`,
  PrivInfoSubTxt: `In Jersey, please contact the: Office of the Information Commissioner.//
  In Guernsey, please contact the: Office of the Data Protection Commissioner.//
  In Isle of Man, please contact the: Information Commissioner.`,
  PrivWidthDrawTxt: `How to withdraw your consent`,
  PrivWidthDrawSubTxt: `This section explains what to do if you change your mind about a consent you have given.`,
  PrivConsentTxt: `You can withdraw your consent at any time. Please contact us if you want to do so.`,
  PrivConsentSubTxt: `This will only affect the way we use information when our reason for doing so is that we have your consent. See the section 'Your Rights' about more generally restricting use of your information.`,
  PrivWithdrawConsentSubTxt: `If you withdraw your consent, we may not be able to provide certain products or services to you. If this is so, we will tell you.`,
  PrivLetUsKnowTxt: `Letting us know if your personal information is incorrect`,
  PrivLetUsKnowSubTxt: `Here you can find out how to contact us if you think the information we hold for you is wrong, incomplete or out of date.`,
  PrivRightQuestionTxt: `You have the right to question any information we have about you that you think is incorrect. We’ll take reasonable steps to check this for you and correct it.`,
  PrivCallUs: `If you want to do this, please or call us on <a href="tel:0344 824 8888">0344 824 8888</a> (+44 344 824 8888 from outside the UK).`,
  PrivCall: `Calls may be monitored or recorded.`,
  PrivCallUsSubTxt: `If you have a hearing or speech impairment you can also call us using Relay UK. If you use a textphone, just add 18001 before the number you're calling. On a smartphone, tablet or computer, you can call through the Relay UK app. There's more information on the Relay UK help pages.`,
  PrivSignVideoTxt: `If you’re Deaf and a BSL user, you can talk to us live through a BSL interpreter online with our BSL SignVideo service.`,
  PrivCopyInfoTxt: `How to get a copy of your personal information`,
  PrivDigitalTxt: `This section tells you where to write to us to get a copy of your personal information, and how to ask for a digital file you can use yourself or share easily with others. You can do this by writing to us.`,
  PrivDigitalSubTxt: `You can get a copy of all the personal information we hold about you by writing to us at this address:`,
  PrivDSAR: `DSAR Unit//
    Black Horse//
    St Williams House//
    Tresillian Terrace//
    Cardiff CF10 5BH`,
  PrivShareData: `When you want to share your data with outside companies`,
  PrivShareDataSubTxt: `You also have the right to get certain personal information from us as a digital file, so you can keep and use it yourself, and give it to other organisations if you choose to. If you wish, we will provide it to you in an electronic format that can be easily re-used, or you can ask us to pass it on to other organisations for you.`,
  PrivShareRecordedTxt: `If you want to do this, please call us on <a href="tel:0344 824 8888">0344 824 8888</a> (+44 344 824 8888 from outside the UK).`,
  PrivShareRecordedSubTxt: `If you have a hearing or speech impairment you can also call us using the Next Generation Text (NGT) service. Simply dial 18001 and then the telephone number.`,
  PrivYourRights: `Your rights`,
  PrivYourRightsSubTxt: `What if you want us to stop using your personal information? This section explains about your right to object and other data privacy rights you have – as well as how to contact us about them.`,
  PrivObjectTxT: `You can object to us keeping or using your personal information. This is known as the ‘right to object’.`,
  PrivObjectSubTxT: `You can also ask us to delete, remove, or stop using your personal information if there is no need for us to keep it. This is known as the ‘right to erasure’ or the ‘right to be forgotten’.`,
  PrivOfficialTxt: `There may be legal or other official reasons why we need to keep or use your data. But please tell us if you think that we should not be using it.`,
  PrivOfficialSubTxt: `We may sometimes be able to restrict the use of your data. This means that it can only be used for certain things, such as legal claims or to exercise legal rights.`,
  PrivOfficialPersonalTxt: `You can ask us to restrict the use of your personal information if:`,
  PrivOfficialList: `It is not accurate//
  It has been used unlawfully but you don't want us to delete it//
  It is not relevant any more, but you want us to keep it for use in legal claims//
  You have already asked us to stop using your data but you are waiting for us to tell you if we are allowed to keep on using it.`,
  PrivRestrictTxt: `If we do restrict your information in this way, we will not use or share it in other ways while it is restricted.`,
  PrivRestrictSubTxt: `If you want to object to how we use your data, or ask us to delete it or restrict how we use it or, please call us on 0344 824 8888 (+44 344 824 8888 from outside the UK). Calls may be monitored or recorded.`,
  PrivRelayTxt: `If you have a hearing or speech impairment you can also call us using Relay UK. If you use a textphone, just add 18001 before the number you're calling. On a smartphone, tablet or computer, you can call through the Relay UK app. There's more information on the Relay UK help pages.`,
  PrivRelaySubTxt: `If you're Deaf and a BSL user, you can talk to us live through a BSL interpreter online with our BSL SignVideo service`,
  PrivRelayPersonalHeading: `Who we share your personal information with`,
  PrivCreditCardTxt: `We may share your personal information with outside organisations such as credit card providers, insurers or tax authorities. This is so that we can provide you with products and services, run our business, and obey rules that apply to us. Here we list all the types of organisation that we may share your personal information with.`,
  PrivLloydsBankingGroup: 'Lloyds Banking Group',
  PrivLBGShare:
    'We may share your personal information with other companies in Lloyds Banking Group.',
  PrivAuthorities: 'Authorities',
  PrivTxt: `This means official bodies that include:`,
  PrivList: `Central and local government//
  HM Revenue & Customs, regulators and other tax authorities//
  UK Financial Services Compensation Scheme and other deposit guarantee schemes//
  Law enforcement and fraud prevention agencies.//
  Banking and financial services`,
  PrivCompanyTxt: `Outside companies we work with to provide services to you and to run our business.`,
  PrivCollectList: `Agents, suppliers, sub-contractors and advisers. These are types of firm that we use to help us run accounts, policies and services.//
      Agents who help us to collect what is owed to us//
     Credit reference agencies (such as Callcredit, Equifax and Experian)//
    Someone linked with you or your business's product or service. This could mean a joint account holder, trustee, or fellow company director.//
    Other financial services companies (to fulfil a payment or other service as part of a contract with you; or to help prevent, detect and prosecute unlawful acts and fraudulent behaviour)//
    Independent Financial Advisors (if you have one). This could be someone who you, your employer, or product owner have selected to advise on things like pensions or life assurance.//
     Price comparison websites and similar companies. We only do so if you buy something from us through such a company. We only share details needed to confirm what you have bought, to fulfil our own contract with the company.//
    Employers (for instance, to confirm your identity if we ask for a mortgage reference)//
    Companies you ask us to share your data with
    `,
  PrivOpenBankTxt: `This is to do with something called Open Banking, which gives you more freedom and control to use your own banking data. It can make it easier for you or your business to shop around for products like credit cards, savings and current accounts.`,
  PrivInsurers: 'Insurers',
  PrivFraudTxt: `We share personal information with insurance industry companies to process claims and help reduce fraud. We do that in these ways:`,
  PrivInsurerTxt: `If you apply for insurance through us, we may pass your personal or business details to the insurer.`,
  PrivReInsurerTxt:
    'If you apply for insurance with us as the insurer, we may share your personal or business details with reinsurers.',
  PrivInsuranceTxt: `If you make an insurance claim, information you give to us or the insurer may be put on a register of claims. This will be shared with other insurers, our agents, suppliers and sub-contractors. In some cases we may also share it with our business partners, if you also have a relationship with them.`,
  PrivInsurance: `Other services and schemes`,
  PrivProdTxt: `These are organisations that we may need to share your personal information with, because of what you can do with the product or service you have with us.`,
  PrivProdList: `If you have a debit, credit or charge card with us, we will share transaction details with companies which help us to provide this service (such as Visa and Mastercard). This is needed to keep your account balance and statements up to date, for example.//
  If you use direct debits, we will share your data with the Direct Debit scheme.//
  If you have a product which has a loyalty scheme, we will share your data with that scheme.//
  If you have a product with benefits such as travel insurance or discount offers, we will share your data with the benefit providers. We may also share it with other companies involved in how you use the service (such as a private car park operator).//
  If you have a secured loan or mortgage with us, we may share information with other lenders who also hold a charge on the property (For example, the other party in a shared ownership scheme)//
  When placing a vehicle order or arranging delivery, we may pass your information to manufacturers, dealers and/or brokers and all companies within those manufacturer, dealer and/or broker groups for further possible business with you including marketing.//
  If you ask us to provide ancillary services such as daily rental vehicles, accident management, vehicle servicing, maintenance and repair, replacement tyres and glass, breakdown and recovery, and European travel, we will need to share your information with those third party providers.//
  In the case of accidents, offences, fines including foreign fines, penalties, charges including congestion charges and private parking charges, and tolls, we may share you details with third parties who contact us regarding such matters.//
  In the case of early settlement quotations, we will (where required) provide this information to the supplying dealer or broker, and (with your consent) to any other dealer or broker who asks us for one.`,
  PrivGenralTxt: `General business`,
  PrivImproveTxt: `Outside companies we use to help grow and improve our business.`,
  PrivImproveList: `Companies we have a joint venture or agreement to co-operate with (such as a store or car dealership or broker offering finance deals through us)//
  Organisations that introduce you to us. This might be a store or car dealership that offers finance deals through us, or a price comparison website.//
  Market researchers. These firms may get in touch with you on our behalf to ask you for your opinions and feedback. Sometimes these firms will combine what you tell them with data from other sources to study it. The will use this to produce reports and advice that help us understand our customers’ point of view, so that we can improve the way we work as a business.//
  Advisers who help us to come up with new ways of doing business. This might be a legal firm, IT supplier or consultancy.//
  Advertisers and technology providers that you use (such as websites you visit, social networks, and providers of apps and smart devices)`,
  PrivSoThatTxt: 'This is so that:',
  PrivMarketingList: `You are only shown marketing we think you might be interested in.//
  You are not shown marketing we think you won't be interested in.//
  We can try to find new customers who are like you, or have similar interests to yours.//
  Learn more about how we use your information to make automated decisions below.`,
  PrivOutsideCompaniesTxt: 'Outside companies you may use.',
  PrivThisCouldInclude: 'This could include',
  PrivThisCouldIncludeList: `Price comparison websites and similar companies. We only do so if you buy something from us through such a company. We only share details needed to confirm what you have bought, to fulfil our own contract with the company.//
  Third party payment providers, for example if you use an App on your phone to pay for something, then we may share payment and transaction data with them. This includes the amount, type, location, origin and recipients.//
  Company mergers, takeovers and transfers of products or services//`,
  PrivChangesInfuture: `We may also share your personal information if the ownership of products or services or the make-up of Lloyds Banking Group changes in the future:`,
  PrivChangesList: `We may choose to sell, transfer, or merge parts of our business, or our assets, including products or services. Or we may try to bring other businesses into Lloyds Banking Group. This is sometimes called Mergers & Acquisitions or ‘company takeovers’.//
  During any such process, we may share your data with other parties involved. We'll only do this if they agree to keep your data safe and private.//
  If the change to our Group happens, then other parties may use your data in the same way as set out in this notice.//
  Sharing data that does not say who you are`,
  PrivSellData: `We may share or sell some data to other companies outside Lloyds Banking Group, but only when it is grouped so that no-one's identity can be known or found out.`,
  PrivCombineData: `We combine data in this way so we can look for general patterns and trends. For instance, we might look at customers in one age group shopping for clothes. We could look at one area of the UK, or the average amount spent in one month. But we would not include any data about who these customers are. When we combine data this way, we use all of the information - including historical data - that we hold about you and our other customers.`,
  PrivCombineSubTxt: `We do this to learn about the types of customers we have, how they use our products, and how our products perform for them. The law says this is not considered to be personal information after it has been grouped in this way.`,
  PrivWorkOutMarktingHeading: `How we work out what marketing you receive`,
  PrivWorkOut: `We may use your personal information to make decisions about what products, services and offers we think you may be interested in. This is what we mean when we talk about 'marketing'.//
  We can only use your personal information to send you marketing messages if we have either your consent or a 'legitimate interest'. That is when we have a business or commercial reason to use your information. It must not conflict unfairly with your own interests.//
  The personal information we have for you is made up of what you tell us, and data we collect when you use our services, or from outside organisations we work with. We study this to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you.//
  This is called profiling for marketing purposes. You can contact us at any time and ask us to stop using your personal information this way.//
  If you allow it, we may show or send you marketing material online (on our own and other websites including social media), in our own and other apps, or by email, mobile phone, post or through smart devices and other digital channels.//
  What you get will depend on marketing choices that you set. You can change these at any time and tell us to stop sending you marketing.//
  You can also tell us not to collect data while you are using our websites or mobile apps. If you do, you will still see some marketing but it will not be tailored to you. See our Cookies policy for details about how we use this data to improve our websites and mobile apps.//
  Whatever you choose, you'll still receive statements and other important information such as changes to your existing products and services.//
  We do not sell the personal information we have about you to outside organisations.//
  We may ask you to confirm or update your choices, if you take out any new products or services with us in future. We will also ask you to do this if there are changes in the law, regulation, or the structure of our business.//
  If you change your mind you can contact us to update your choices at any time.//
  `,
  PrivAutomatedInfo: `How we use your information to make automated decisions`,
  PrivAutomatedInfoSubTxt: `Here we tell you how we use automated systems to make decisions about you and your money. We also explain the rights you have to challenge decisions made this way.//
  We sometimes use systems to make automated decisions about you or your business. This helps us to make sure our decisions are quick, fair, efficient and correct, based on what we know. Automated decisions can affect the products, services or features we may offer you now or in the future, or the price that we charge you for them. They are based on personal information that we have or that we are allowed to collect from others.//
  Here are the types of automated decision we make://
  Pricing//
  We may decide what to charge for some products and services based on what we know. For instance, if you use our online mortgage calculator, it will use the personal financial details you put in to estimate the kind of mortgage we may offer you. If you apply for insurance, we will compare what you tell us with other records to work out how likely you are to make a claim. This will help us to decide whether to offer you the product and what price to charge you.//
  Tailoring products, services, offers and marketing//
  We may place you in groups with similar customers. These are called customer segments. We use these to study and learn about our customers' needs, and to make decisions based on what we learn. This helps us to design products, services and offers for different customer segments, and to manage our relationships with them.//
  We also use customer segments from outside companies we work with - such as social networks - by asking them for groups of people who are similar to you, or have interests that are like yours in some ways. These companies help us look for new customers who may be interested in products, services or offers that our existing customers are interested in.//
  This helps us tailor the marketing that individuals receive or are shown on our own and other websites and mobile apps, including social media. It also helps us to design products, services and offers for different customer segments, and to manage our relationships with them.//
  Detecting fraud//
  We use your personal information to help decide if your personal or business accounts may be being used for fraud or money-laundering. We may detect that an account is being used in ways that fraudsters work. Or we may notice that an account is being used in a way that is unusual for you or your business. If we think there is a risk of fraud, we may stop activity on the accounts or refuse access to them.//
  Opening accounts//
  When you open an account with us, we check that the product or service is relevant for you, based on what we know. We also check that you or your business meet the conditions needed to open the account. This may include checking age, residency, nationality or financial position. It may mean we cannot offer you the account you want.//
  Approving credit//
  We use a system to decide whether to lend money to you or your business, when you apply for credit such as a loan or credit card. This is called credit scoring. It uses past data to assess how you're likely to act while paying back any money you borrow. This includes data about similar accounts you may have had before.//
  Credit scoring uses data from three sources://
  `,
  PrivAutomatedSubTxt: `Your application form//
  Credit reference agencies//
  Data we may already hold.//
  It gives an overall assessment based on this. Banks and other lenders use this to help us make responsible lending decisions that are fair and informed.`,
  PrivUnbiasedTxt: `Credit scoring methods are tested regularly to make sure they are fair and unbiased.`,
  PrivUnbiasedSubTxt: `This credit score can determine whether we will give you credit, or allow you to take another product or service.`,
  PrivReviewTxt: `You can object to an automated decision we have made, and ask that a person reviews it.`,
  PrivPleaseContactUs: `If you want to know more about these rights, please contact us.`,
  privCRAHeading: 'Credit Reference Agencies (CRAs)',
  privSection:
    'This section explains how we work with outside companies to decide whether to lend you money through products like credit cards or loans. It explains what we do and why we do it.',
  privCreditAndIdentity:
    'We carry out credit and identity checks when you apply for a product or services for you or your business. We may use Credit Reference Agencies to help us with this. ',
  privSearchInfo:
    'If you use our services, from time to time we may also search information that the CRAs have, to help us manage those accounts.',
  privExchangeTitle:
    'We will share your personal information with CRAs and they will give us information about you. The data we exchange can include:',
  privExchangeInfoList: `Name, address and date of birth//
  Credit application//
  Details of any shared credit//
  Financial situation and history//
  Fraud prevention information//
  Public information, from sources such as the Electoral Register and Companies House.`,
  privUsedataHeading: `We'll use this data to:`,
  privUseDataList: `Assess whether you or your business is able to afford to make repayments//
  Make sure what you've told us is true and correct//
  Help detect and prevent financial crime//
  Manage your accounts with us//
  Trace and recover debts//
  Make sure any offers are relevant for you.//
  We will go on sharing your personal information with CRAs for as long as you are a customer. This will also include details of funds going into the account, and the account balance. If you borrow, it will also include details of your repayments and whether you repay in full and on time. We will also tell the CRAs when you settle your accounts with us. The CRAs may give this information to other organisations that want to check your credit status.`,
  privCRABussiness:
    'When we ask CRAs about you or your business, they will note it on your credit file. This is called a credit search. Other lenders may see this and we may see credit searches from other lenders.',
  privApplyForSomeOne:
    'If you apply for a product with someone else, we will link your records with theirs. We will do the same if you tell us you have a spouse, partner or civil partner - or that you are in business with other partners or directors. ',
  privRecordsLinked:
    'You should tell them about this before you apply for a product or service. It is important that they know your records will be linked together, and that credit searches may be made on them.',
  privCRArecords:
    'CRAs will also link your records together. These links will stay on your files unless one of you asks the CRAs to break the link. You will normally need to give proof that you no longer have a financial link with each other. ',
  privMoreOnCRA:
    'You can find out more about the CRAs on their websites, in the Credit Reference Agency Information Notice. This includes details about:',
  privWhoAreTheyHeading: 'Who they are',
  privWhoAreTheyList: `Their role as fraud prevention agencies//
  The data they hold and how they use it//
  How they share personal information//
  How long they can keep data//
  Your data protection rights.`,
  privLinksToInfo:
    'Here are links to the information notice for each of the three main Credit Reference Agencies:',
  privTransUnionLink: 'TransUnion',
  privEquifax: 'Equifax',
  privExperian: 'Experian',
  privFPAHeading: 'Fraud prevention agencies',
  privConfirmIdentity:
    'We may need to confirm your identity before we provide products or services to you or your business. This may include carrying out fraud checks at the point of sale.',
  privBecomeCustomer:
    'Once you have become a customer of ours, we will share your personal information as needed to help combat fraud and other financial crime. The organisations we share data with are:',
  privRFPAHeading: 'Registered Fraud Prevention Agencies (FPAs)',
  agencyAndBodyHeading:
    'Other agencies and bodies acting for the same purpose:',
  agencyAndBody: 'Industry databases used for this purpose//Insurers',
  preventDetect:
    'Throughout our relationship with you, we and these organisations exchange data between us to help prevent, deter, detect and investigate fraud and money-laundering.',
  obeyingTheLaw: `None of us can use your personal information unless we have a proper reason to do so. It must be needed either for us to obey the law, or for a 'legitimate interest'.`,
  legitimateIntrestTitle: `When we have a business or commercial reason of our own to use your information, this is called a 'legitimate interest'. We will tell you what that is, if we are going to rely on it as the reason for using your data. Even then, it must not unfairly go against your interests.`,
  useInformationTitle: 'We will use the information to:',
  useInfoContent: `Confirm identities//
  Help prevent fraud and / or money-laundering//
  Fulfil any contracts you or your business has with us.//
  We or an FPA may allow law enforcement agencies to access your personal information. This is to support their duty to prevent, detect, investigate and prosecute crime.`,
  otherOrgTitle:
    'These other organisations can keep personal information for different lengths of time, up to six years.',
  personalInfoHeading: 'The information we use',
  PersonalInfoTitle:
    'These are some of the kinds of personal information that we use:',
  KindsOfPersonalInfo: `Name//
  Date of birth//
  Residential address//
  History of where you have lived//
  Contact details, such as email addresses and phone numbers//
  Financial data//
  Whether you have been a victim of fraud//
  Data about insurance claims you have made//
  Data relating to your or your businesses products or services//
  Employment details//
  Vehicle details//
  Data that identifies computers or other  devices you use to connect to the internet. This includes your Internet Protocol (IP) address.//
  Automated decisions for fraud prevention`,
  ThirdPartyContent: `The information we have for you or your business is made up of what you tell us, and data we collect when you use our services, or from third parties we work with.`,
  PreventFraudContent: `We and other organisations acting to prevent fraud may process your personal information in systems that look for fraud by studying patterns in the data. We may find that an account or policy is being used in ways that fraudsters work. Or we may notice that an account is being used in a way that is unusual for you or your business. Either of these could indicate a risk that fraud or money-laundering may be carried out against a customer, the bank or the insurer.`,
  AffectTitle: `How this can affect you`,
  DataFPA: `If we or an FPA decide there is a risk of fraud, we may stop activity on the accounts or block access to them. FPAs and cross-industry organisations may also keep a record of the risk that you or your business may pose.`,
  DataFPAResult: `This may result in other organisations refusing to provide you with products or services, or to employ you.`,
  DataTransferUKaandEEA: `Data transfers out of the UK and EEA`,
  FPADescription: `FPAs and other organisations we share data with for these purposes may send personal information to countries outside the UK and European Economic Area ('EEA'). When they do, there will be a contract in place to make sure the recipient protects the data to the same standard as the EEA. This may include following international frameworks for making data sharing secure.`,
  FPATitle: `Here is the web page for the information notice of the main Fraud Prevention Agency we use:`,
  CIFASTitle: `CIFAS - https://www.cifas.org.uk/fpn`,
  DataOutsideUkandEEA: `Sending data outside the UK and EEA`,
  DataOutsideUkandEEAContent: `This section tells you about the safeguards that keep your personal information safe and private, if it is sent outside the UK and EEA.`,
  DataOutside: `We will only send your data outside of the UK and European Economic Area ('EEA') to:`,
  DataOfEEA: `Follow your instructions. For example, if you book a hotel in Australia with your Lloyds Banking Group card we will send your details necessary to fulfil that payment to the hotel's bank.//Comply with a legal duty. For example, we share information about US citizens with the US tax authority.//Work with our suppliers who help us to run your accounts and services.//If we do transfer your personal information outside the UK and EEA to our suppliers, we will make sure that it is protected to the same extent as in the UK and EEA. We'll use one of these safeguards:`,
  Transfer: `Transfer it to a non-EEA country with privacy laws that give the same protection as the UK and EEA. Learn more on the European Commission Justice website.`,
  TransferContent: `Put in place a contract with the recipient that means they must protect it to the same standards as the UK and EEA. Read more about this here on the European Commission Justice website.`,
  goBackLink: 'Go back',
};

export default privacyPolicy;
