import { AppContent } from '../AppContent';

const common: AppContent['common'] = {
  phoneNumber: '0344 824 8888',
  continueLabel: 'Continue',
  confirmAndContinueLabel: 'Confirm and continue',
  cancelLabel: 'Cancel',
  contactUsLink: 'Contact us',
  previousTitle: 'Previous',
  spinnerLoadingContent: 'This may take a few moments...',
  forgottenEmailLink: 'Forgotten your email address? ',
  forgottenPasswordLink: 'Forgotten your password?',
  needHelpText: 'Need help?',
  saveLabel: 'Save',
  detailsDontLookRightError:
    'These details don’t look right. Please try again. ',
  emailErrorMessage: 'Please enter a valid email address.',
  createAccountLabel: 'Create online account',
  registrationLabel: 'Log in',
  loginHelpLabel: 'Log in help',
  emailAddressLabel: 'Email address',
  passwordLabel: 'Password',
  goBackLink: 'Go back',
};

export default common;
