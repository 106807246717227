import { DataModalInterface } from '../action/dataModal.action';

export interface IDataModalDetails {
  openDialog: boolean;
}
const initialState = {
  openDialog: false,
};
export const DataModalReducer = (
  state: IDataModalDetails = initialState,
  action: DataModalInterface = {
    type: '',
    ...initialState,
  },
): IDataModalDetails => {
  if (action.type === 'OPEN_DATAMODAL') {
    return {
      openDialog: action.openDialog,
    };
  }
  return state;
};
