export const WelcomePage = '/';
export const Registration = '/public/registration';
export const HelpCentre = '/public/help-centre';
export const OtherApp = 'others';
export const ForgotEmail = '/public/forgot-email';
export const ForgotPassword = '/public/forgot-password';
export const RegistrationWss = '/public/registration';
export const LoginWss = '/public/login';
export const TermsAndConditions = '/public/terms-conditions';
export const CookiePolicy = '/public/cookie-policy';
export const PrivacyPolicy = '/public/privacy-policy';
export const TestPage = '/test';
export const DownPage = '/down-page';
