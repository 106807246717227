import React, { ReactElement, useEffect, useState } from 'react';

import { Button, Grid, GridItem, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useDispatch, useSelector } from 'react-redux';

import { ForgotPasswordContent } from './forgotPassword.config';
import LoginAuthenticate from './loginAuthenticate';
import SetupPassword from './setupPassword';
import VerifyEmailAddress from './verifyEmailAddress';
import { authSMSSender } from '../../apis/authSMSSenderApi';
import { authConfirmEmailAPI } from '../../apis/confirmEmailApi';
import { StyledBox } from '../../components/appConfig/common.styled';
import FormStepper from '../../components/formStepper/FormStepper';
import NavigatePreviousLink from '../../components/navigatePreviousLink';
import NeedHelp from '../../components/needHelp/NeedHelp';
import OTPAuthentication from '../../components/otpAuthentication/otpAuthentication';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  changeStepAction,
  changeNumberOfStepsAction,
} from '../../store/action/formStep.action';
import { toggleAction } from '../../store/action/toggler.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { ApiAuthResponse } from '../registration/Registration.config';

export default function ForgotPassword({
  brand,
  contactNumber,
  mtaCustomerId,
  isContactFound,
  numberOfSteps,
  isAuthenticated,
  authLiteUUID,
  handlePassDetails,
  handleAuthenticated,
  isForgetPasswordWss,
  hanldeIsContactFound,
  handleMultiAgreement,
  isMultiAgreement,
  authToken,
  setAuthToken,
}): ReactElement {
  const isDeviceFound = useDeviceResizing();
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [journeySessionId, setJourneySessionId] = useState('');
  const [isEmailError, setIsEmailError] = useState(true);
  const [isCustomerIdentificationError, setIsCustomerIdentificationError] =
    useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const dispatch = useDispatch();
  const utilsStore = useSelector((state: RootState) => state.UtilsReducer);
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const {
    apiEmailErrorMessage,
    authenticateHeading,
    resetPasswordTitle,
    continueLabel,
    sendPasscodeLabel,
    forgottenPasswordLink,
    needHelpLoggingIn,
  } = useContent<ForgotPasswordContent>();
  useEffect(() => {
    dispatch(ShowHideSpinnerAction(false));
    dispatch(DataModelAction(false));
    dispatch(changeNumberOfStepsAction(numberOfSteps));
  }, [dispatch, numberOfSteps]);

  const handleOnStep = (): void => {
    dispatch(
      changeStepAction(
        stepNumber < numberOfSteps ? stepNumber + 1 : numberOfSteps,
      ),
    );
  };

  const renderOTPComponent = (): void => setIsOTP(true);

  const handleSetEmailAddress = (value: string): void => setEmailAddress(value);

  const handleEmailError = (value: boolean): void => setIsEmailError(value);

  const handleAuthToken = (value: string): void => setAuthToken(value);

  const handleOnClickContinue = (): void => {
    setIsContinueClicked(true);
    if (!isEmailError && !isContactFound && !isOTP) {
      setIsApiCalled(true);
      setIsContinueClicked(false);
      dispatch(ShowHideSpinnerAction(true));
      authConfirmEmailAPI(emailAddress)
        .then((res: { data: ApiAuthResponse }) => {
          handlePassDetails([res.data], {
            isForgotPassword: false,
          });
          setIsApiCalled(false);
          dispatch(ShowHideSpinnerAction(false));
        })
        .catch((err) => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          if (err.response.data[0].errors === apiEmailErrorMessage) {
            setIsCustomerIdentificationError(true);
            handleAuthenticated(true);
          }
        });
    } else if (stepNumber === 1 && isContactFound) {
      setIsApiCalled(true);
      dispatch(ShowHideSpinnerAction(true));
      authSMSSender(mtaCustomerId)
        .then((res: any) => {
          dispatch(ShowHideSpinnerAction(false));
          setJourneySessionId(res.authSessionId);
          renderOTPComponent();
          setIsContinueClicked(false);
          setIsApiCalled(false);
        })
        .catch(() => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          setIsContinueClicked(false);
        });
    }
  };
  const renderStepTitle = (): string => {
    if (stepNumber === 1) {
      return authenticateHeading;
    }
    return resetPasswordTitle;
  };
  const renderContinueButtonLabel = (): string => {
    if (stepNumber === 1 && !isContactFound) {
      return continueLabel;
    }
    return sendPasscodeLabel;
  };
  const handleOnSessionId = (value: string): void => {
    setJourneySessionId(value);
  };
  const handleOnButtonRenderCondition = (): boolean => {
    if (stepNumber === 1 && isAuthenticated && !isContactFound) {
      return false;
    }
    if (stepNumber === 1 && !isOTP && isAuthenticated && isContactFound) {
      return true;
    }
    if (
      stepNumber === 1 &&
      !isOTP &&
      !isCustomerIdentificationError &&
      !isContactFound
    ) {
      return true;
    }
    if (stepNumber === 2) return false;
    return stepNumber === 2 && !isOTP;
  };
  const renderButton = (): ReactElement =>
    handleOnButtonRenderCondition() && (
      <Grid alignX="right">
        <Button onClick={handleOnClickContinue}>
          {renderContinueButtonLabel()}
        </Button>
      </Grid>
    );
  const renderStepContent = (): ReactElement => {
    if (stepNumber === 1 && isAuthenticated && !isOTP) {
      return (
        <LoginAuthenticate
          isCustomerIdentificationError={isCustomerIdentificationError}
          isNumberFound={isContactFound}
          phoneNumber={contactNumber}
        />
      );
    }
    if (stepNumber === 1 && isOTP) {
      return (
        <OTPAuthentication
          brand={brand}
          handleSendOtp={handleOnClickContinue}
          phoneNumber={contactNumber}
          isMobileFound={isDeviceFound.mobile}
          sessionId={journeySessionId}
          isForgetPassword
          handleStepper={handleOnStep}
          mtaCustomerId={mtaCustomerId}
          handleSessionId={handleOnSessionId}
          handleAuthToken={handleAuthToken}
        />
      );
    }
    if (stepNumber === 2) {
      return (
        <SetupPassword
          isForgotPassword
          handleApiCalled={setIsApiCalled}
          handleStepper={handleOnStep}
          mtaCustomerId={mtaCustomerId}
          userId={authLiteUUID}
          brand={brand}
          authToken={authToken}
        />
      );
    }
    return (
      <VerifyEmailAddress
        handleEmailAddress={handleSetEmailAddress}
        isContinueClicked={isContinueClicked}
        handleEmailError={handleEmailError}
      />
    );
  };
  const handlePreviousNavigate = (): void => {
    if (stepNumber === 1 && !isOTP && isMultiAgreement) {
      handleMultiAgreement(true);
      dispatch(toggleAction(true));
    } else if (stepNumber === 1 && (isForgetPasswordWss || !isWss) && !isOTP) {
      dispatch(toggleAction(true));
      dispatch(ShowHideSpinnerAction(false));
    } else if (stepNumber === 2) {
      dispatch(changeStepAction(stepNumber - 1));
      setIsOTP(true);
    } else if (isOTP) {
      setIsOTP(false);
    } else if (isAuthenticated) {
      handleAuthenticated(false);
      hanldeIsContactFound(false);
      setIsCustomerIdentificationError(false);
      setIsContinueClicked(false);
    } else if (stepNumber === 1 && !isForgetPasswordWss) {
      window.history.back();
    } else {
      dispatch(changeStepAction(stepNumber - 1));
    }
  };
  const handleOnRender = (): ReactElement =>
    isApiCalled && utilsStore.showSpinner ? (
      <SpinnerContainer brand={brand} />
    ) : (
      <Grid>
        <GridItem lg={2} />
        <GridItem lg={8}>
          <NavigatePreviousLink
            navigateRoute={handlePreviousNavigate}
            headingText={forgottenPasswordLink}
            dataQaId={dataQaIds.loginAndRegistration.previousLink}
          />
          <Heading size="s7" marginBottom="03" aria-level={2}>
            {renderStepTitle()}
          </Heading>
          <FormStepper activeStep={stepNumber} numberOfSteps={numberOfSteps} />
          <StyledBox padding>
            <Grid>{renderStepContent()}</Grid>
            <GridItem>{renderButton()}</GridItem>
          </StyledBox>
          <NeedHelp
            hasHeading
            customSubtitle={needHelpLoggingIn}
            dataQaId={dataQaIds.needhelp.regContainer}
          />
        </GridItem>
        <GridItem lg={2} />
      </Grid>
    );
  return handleOnRender();
}
